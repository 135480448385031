import React, { useState, useEffect } from "react";
import axios from "axios";
import Dividends from "../dividends/dividends.component";
import Dividendss from "../dividends/dividends_";

import { connect } from "react-redux";
import * as actions from "../../actions";
import Contract_obj from "../../getTronweb";
import metacoinConfig from "../../metacoin-config.js";
import styles from "./holdings.module.scss";
import web3_obj from "../../getWeb3";
import { Area } from "@antv/g2plot";
import Example from "../ChartBox";
import Vault_obj from "../../getWrappedContract";
import Vault_obj_eth from "../../getWrappeddContractWeb3";
import SocialSharing from "../SocialSharing";
const Holdings = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  ...props
}) => {
  const [tokens, setTokens] = useState();
  const [dividends, setDividends] = useState();
  const [balance, setBalance] = useState();
  const [buyPrice, setBuyPrice] = useState();
  const [sellPrice, setSellPrice] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [plot, setPlot] = useState();
  const [cetoIfReinvested, setCetoIfReinvested] = useState();
  const [usd, setUsd] = useState();
  const [currenyArray, setCurrenyArray] = useState([]);
  const [ref_income, setRefIncome] = useState();
  const [ref_balance, setRefBalance] = useState();
  const [ceto, setCeto] = useState();
  const [wceto, setWceto] = useState();
  const [rate, setRate] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const wrappedData = async () => {
    console.log("wrappedData");
    if (props.utils.blockchain == "trx") {
      window.tronWeb &&
        (await Vault_obj.init(
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : window.tronWeb.fullNode.host == "https://api.trongrid.io"
            ? "mainnet"
            : "private"
        ));
      Vault_obj.contract_instace &&
        (await Vault_obj.contract_instace
          .balanceOf(Vault_obj.accounts[0])
          .call({
            from: Vault_obj.accounts[0],
            feeLimit: Vault_obj.feeLimit,
          })
          .then(async res => {
            if (res) {
              console.log(res.toString(), "balanceOf");
              setWceto(parseFloat(res.toString()) / factor);
              Vault_obj.contract_instace &&
                (await Vault_obj.contract_instace
                  .cetoTokensTransfered_(res.toString())
                  .call({
                    from: Vault_obj.accounts[0],
                    feeLimit: Vault_obj.feeLimit,
                  })
                  .then(function (res) {
                    if (res) {
                      console.log(res.toString(), "CETO TOKENS");
                      setCeto(parseFloat(res.toString()) / factor);
                    }
                  }));
            }
          }));
      Vault_obj.contract_instace &&
        (await Vault_obj.contract_instace
          .getWrappedCetoRate()
          .call({
            from: Vault_obj.accounts[0],
            feeLimit: Vault_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              console.log(res.toString(), "getWrappedCetoRatee");
              setRate(parseFloat(res.toString()) / 1e3);
            }
          }));
    } else if (props.utils.blockchain == "eth") {
      await Vault_obj_eth.init();
      console.log(Vault_obj_eth, "checkk");
      if (
        Vault_obj_eth &&
        Vault_obj_eth.accounts[0] &&
        Vault_obj_eth.accounts[0][0] &&
        Vault_obj_eth.contract_instace
      ) {
        var res = await Vault_obj_eth.contract_instace.methods
          .balanceOf(Vault_obj_eth.accounts[0][0])
          .call({
            from: Vault_obj_eth.accounts[0][0],
            gas: Contract_obj.feeLimit,
          });
        setWceto(parseFloat(res) / factor);

        if (res) {
          var res = await Vault_obj_eth.contract_instace.methods
            .cetoTokensTransfered_(res)
            .call({
              from: Vault_obj_eth.accounts[0][0],
              gas: Contract_obj.feeLimit,
            });
          setCeto(parseFloat(res) / factor);
        }
        var res = await Vault_obj_eth.contract_instace.methods
          .getWrappedCetoRate()
          .call({
            from: Vault_obj_eth.accounts[0][0],
            gas: Contract_obj.feeLimit,
          });
        setRate(parseFloat(res.toString()) / 1e3);
      }
    } else {
      await Vault_obj_eth.init();
      console.log(Vault_obj_eth, "checkk bnb");
      if (
        Vault_obj_eth &&
        Vault_obj_eth.accounts[0] &&
        Vault_obj_eth.accounts[0][0] &&
        Vault_obj_eth.contract_instace &&
        (Vault_obj_eth.networkId == 97 || Vault_obj_eth.networkId == 56)
      ) {
        var res = await Vault_obj_eth.contract_instace.methods
          .balanceOf(Vault_obj_eth.accounts[0][0])
          .call({
            from: Vault_obj_eth.accounts[0][0],
            gas: Contract_obj.feeLimit,
          });
        setWceto(parseFloat(res) / factor);

        if (res) {
          var res = await Vault_obj_eth.contract_instace.methods
            .cetoTokensTransfered_(res)
            .call({
              from: Vault_obj_eth.accounts[0][0],
              gas: Contract_obj.feeLimit,
            });
          setCeto(parseFloat(res) / factor);
        }
        var res = await Vault_obj_eth.contract_instace.methods
          .getWrappedCetoRate()
          .call({
            from: Vault_obj_eth.accounts[0][0],
            gas: Contract_obj.feeLimit,
          });
        setRate(parseFloat(res.toString()) / 1e3);
      }
    }
  };

  const trxData = async () => {
    // await Contract_obj.init();
    console.log(props, " propsss");
    setFactor(1e6);
    await Contract_obj.contract_instace
      .myTokens()
      .call({
        from: Contract_obj.accounts[0],
        feeLimit: Contract_obj.feeLimit,
      })
      .then(async res => {
        if (res) {
          console.log(res.toString(), "TOKENS", factor);
          setTokens(parseFloat(res.toString()) / factor);
          if (parseFloat(res.toString()) == 0) {
            setBalance(0);
          } else {
            await Contract_obj.contract_instace
              .tokensToTron_(res.toString())
              .call({
                from: Contract_obj.accounts[0],
                feeLimit: Contract_obj.feeLimit,
              })
              .then(function (tron) {
                setBalance(parseFloat(tron.toString()) / factor);
              });
          }
        }
      });
    var obj = setInterval(async () => {
      (await Contract_obj.tronWebProvider) &&
        Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.contractAddress)
          .then(result => {
            setContractBalance(result);
            clearInterval(obj);
          });
    }, 2);
    await Contract_obj.contract_instace
      .calculateTokensReinvested()
      .call({
        from: Contract_obj.accounts[0],
        feeLimit: Contract_obj.feeLimit,
      })
      .then(function (res) {
        if (res) {
          console.log(res.toString(), "setTokens");
          setCetoIfReinvested(parseFloat(res.toString()) / factor);
        }
      });
    await Contract_obj.contract_instace
      .myDividends(true)
      .call({
        from: Contract_obj.accounts[0],
        feeLimit: Contract_obj.feeLimit,
      })
      .then(function (res) {
        if (res) {
          console.log(res.toString(), "myDividends");
          setDividends(parseFloat(res.toString()) / factor);
        }
      });
    Contract_obj.contract_instace &&
      (await Contract_obj.contract_instace
        .getReferralBalance()
        .call({
          from: Contract_obj.accounts[0],
          feeLimit: Contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            setRefBalance(parseFloat(res[0].toString()));
            setRefIncome(parseFloat(res[1].toString()));
          }
        }));
    var obj = setInterval(async () => {
      if (window.tronWeb && Contract_obj.contract_instace) {
        await Contract_obj.contract_instace
          .buyPrice()
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              setBuyPrice(parseFloat(res.toString()));
            }
          });
        await Contract_obj.contract_instace
          .sellPrice()
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              setSellPrice(parseFloat(res.toString()));
            }
          });
      }
      if (buyPrice && sellPrice) {
        clearInterval(obj);
      }
    }, 2000);
  };
  const ethData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 1 || web3_obj.networkId == 3)
    ) {
      var c_balance = await web3_obj.web3_instance.eth.getBalance(
        web3_obj.contract_instace.options.address
      );
      setContractBalance(parseFloat(c_balance));
      var price = await web3_obj.contract_instace.methods
        .buyPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setBuyPrice(parseFloat(price));

      var price = await web3_obj.contract_instace.methods
        .sellPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setSellPrice(parseFloat(price));
      var referral_ = await web3_obj.contract_instace.methods
        .getReferralBalance()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(referral_, "referral_");
      setRefBalance(referral_[0].toString());
      setRefIncome(referral_[1].toString());
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseFloat(my_tokens) / 1e18);
      if (my_tokens == 0) {
        setBalance(0);
      } else {
        var tokensToEthereum_ = await web3_obj.contract_instace.methods
          .tokensToEthereum_(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        setBalance(parseFloat(tokensToEthereum_) / 1e18);
      }
      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(false)
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseFloat(myDividends) / 1e18);

      var res = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setCetoIfReinvested(parseFloat(res) / 1e18);
    }
  };
  const bnbData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var c_balance = await web3_obj.web3_instance.eth.getBalance(
        web3_obj.contract_instace.options.address
      );
      setContractBalance(parseFloat(c_balance));
      // console.log("contract balance", c_balance);
      var price = await web3_obj.contract_instace.methods
        .buyPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setBuyPrice(parseFloat(price));
      // console.log("buyPrice", price);

      var price = await web3_obj.contract_instace.methods
        .sellPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setSellPrice(parseFloat(price));
      // console.log("sellPrice", price);

      var referral_ = await web3_obj.contract_instace.methods
        .getReferralBalance()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setRefBalance(referral_[0].toString());
      setRefIncome(referral_[1].toString());
      // console.log("referral_", referral_);

      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(true)
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseFloat(myDividends) / factor);
      console.log("myDividends", myDividends);

      var res = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setCetoIfReinvested(parseFloat(res) / factor);
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseFloat(my_tokens) / factor);
      console.log("setTokens", my_tokens);

      if (my_tokens == 0) {
        setBalance(0);
        console.log("setBalancee", 0);
      } else {
        var res = await web3_obj.contract_instace.methods
          .tokensToEthereum_(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        setBalance(parseFloat(res) / factor);
        console.log("setBalancee", res);
      }

      // console.log("setCetoIfReinvested", res);
    }
  };
  useEffect(() => {
    wrappedData();

    if (props.utils.blockchain == "eth") {
      ethData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      Contract_obj.contract_instace &&
        window.tronWeb &&
        window.tronWeb.defaultAddress.base58 &&
        trxData();
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            Contract_obj.contract_instace &&
              window.tronWeb &&
              window.tronWeb.defaultAddress.base58 &&
              trxData();
          }
        }
        if (e.data.message && e.data.message.action == "setAccount") {
          Contract_obj.contract_instace &&
            window.tronWeb &&
            window.tronWeb.defaultAddress.base58 &&
            trxData();
          Vault_obj.contract_instace && wrappedData();
        }
      });
    }
  }, []);

  useEffect(() => {
    setBuyPrice();
    setSellPrice();
    setWceto();
    setCeto();
    setRate();
    setContractBalance();
    setTokens();
    setBalance();
    setRefBalance();
    setRefIncome();
    console.log(props.utils.currencies, "curr");
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth"
      ? ethData()
      : props.utils.blockchain == "bnb"
      ? bnbData()
      : Contract_obj.contract_instace &&
        window.tronWeb &&
        window.tronWeb.defaultAddress.base58 &&
        trxData();
    wrappedData();
  }, [
    updated,
    factor,
    props.utils.blockchain,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
  ]);
  return (
    <div className={styles.holdings}>
      {/* <span className={styles.holdings__title}>Holdings</span> */}

      <div className={styles.holdingsCards}>
        <Dividends
          flag={1}
          heading="tokens"
          value={`${tokens ? tokens.toFixed(3) : " "} CETO`}
          footer={[
            `${
              balance || balance == 0 ? balance.toFixed(3) : ""
            } ${props.utils.blockchain.toUpperCase()}`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              balance || balance == 0
                ? (balance * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
        />

        {props.match.path != "/exchange" && (
          <Dividendss
            flag={1}
            heading="dividends"
            value={`${
              dividends ? dividends.toFixed(3) : ""
            } ${props.blockchain.toUpperCase()}`}
            footer={[
              `${
                cetoIfReinvested ? cetoIfReinvested.toFixed(2) : " "
              } CETO (if reinvested)`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.USD).toFixed(2)
                  : ""
              } USD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.CHF).toFixed(2)
                  : ""
              } CHF`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.EUR).toFixed(2)
                  : ""
              } EUR`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.GIP).toFixed(2)
                  : ""
              } GIP`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.KWD).toFixed(2)
                  : ""
              } KWD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.GBP).toFixed(2)
                  : ""
              } GBP`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.JOD).toFixed(2)
                  : ""
              } JOD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.OMR).toFixed(2)
                  : ""
              } OMR`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.BHD).toFixed(2)
                  : ""
              } BHD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.CAD).toFixed(2)
                  : ""
              } CAD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.INR).toFixed(2)
                  : ""
              } INR`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.JPY).toFixed(2)
                  : ""
              } JPY`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.HKD).toFixed(2)
                  : ""
              } HKD`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.PAB).toFixed(2)
                  : ""
              } PAB`,
              `${
                dividends
                  ? (dividends * props.utils.currencies.AED).toFixed(2)
                  : ""
              } AED`,
            ]}
          />
        )}
        <Dividends
          heading="Contract Balance"
          flag={0}
          value={`${
            contractBalance ? (contractBalance / factor).toFixed(3) : ""
          }  ${props.utils.blockchain.toUpperCase()}`}
          footer={[
            `${
              contractBalance || contractBalance == 0
                ? (contractBalance / factor).toFixed(3)
                : ""
            }  ${props.utils.blockchain.toUpperCase()}`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.USD
                  ).toFixed(2)
                : ""
            } USD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.CHF
                  ).toFixed(2)
                : ""
            } CHF`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.EUR
                  ).toFixed(2)
                : ""
            } EUR`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.GIP
                  ).toFixed(2)
                : ""
            } GIP`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.KWD
                  ).toFixed(2)
                : ""
            } KWD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.GBP
                  ).toFixed(2)
                : ""
            } GBP`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.JOD
                  ).toFixed(2)
                : ""
            } JOD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.OMR
                  ).toFixed(2)
                : ""
            } OMR`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.BHD
                  ).toFixed(2)
                : ""
            } BHD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.CAD
                  ).toFixed(2)
                : ""
            } CAD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.INR
                  ).toFixed(2)
                : ""
            } INR`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.JPY
                  ).toFixed(2)
                : ""
            } JPY`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.HKD
                  ).toFixed(2)
                : ""
            } HKD`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.PAB
                  ).toFixed(2)
                : ""
            } PAB`,
            `${
              contractBalance || contractBalance == 0
                ? (
                    (contractBalance / factor) *
                    props.utils.currencies.AED
                  ).toFixed(2)
                : ""
            } AED`,
          ]}
        />
        <Dividends
          flag={0}
          heading="Buy Price"
          value={`${
            buyPrice ? (buyPrice / factor).toFixed(3) : " "
          }  ${props.utils.blockchain.toUpperCase()}`}
          footer={[
            `${
              buyPrice ? (buyPrice / factor).toFixed(3) : " "
            }  ${props.utils.blockchain.toUpperCase()}`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              buyPrice || buyPrice == 0
                ? ((buyPrice / factor) * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
        />
        <Dividends
          flag={0}
          heading="Sell Price"
          value={`${
            sellPrice ? (sellPrice / factor).toFixed(3) : " "
          }  ${props.utils.blockchain.toUpperCase()}`}
          footer={[
            `${
              sellPrice ? (sellPrice / factor).toFixed(3) : " "
            }  ${props.utils.blockchain.toUpperCase()}`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              sellPrice || sellPrice == 0
                ? ((sellPrice / factor) * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
        />
        <Dividends
          flag={2}
          heading="Referral Balance"
          value={`${
            ref_balance || ref_balance == 0
              ? (ref_balance / factor).toString().slice(0, 7)
              : ""
          }  ${props.utils.blockchain.toUpperCase()}`}
          footer={[
            `${
              ref_income ? (ref_income / factor).toFixed(2) : " "
            }  ${props.utils.blockchain.toUpperCase()} (Referral Income)`,
          ]}
          {...props}
        />
        {props.match.path != "/wrapped-ceto" && (
          <Dividends
            flag={4}
            heading="WCETO Holdings"
            value={`${wceto ? wceto.toFixed(3) : ""} WCETO`}
            footer={[
              `${ceto ? ceto.toFixed(3) : " "} CETO `,
              `${rate ? rate.toFixed(3) : " "} CETO/WCETO `,
            ]}
            {...props}
          />
        )}
        <div className={styles.container1}>
          <span className={styles.dividends__heading}>Growth</span>

          <Example></Example>
        </div>
      </div>
      <SocialSharing open={openModal} setIsOpen={setOpenModal}></SocialSharing>
    </div>
  );
};

const mapStateToProps = state => {
  // console.log(state, "state");
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Holdings);
