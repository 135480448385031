import "bootstrap/dist/css/bootstrap-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bulma/css/bulma.css";
import localForage from "localforage";
import React, { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import persistStore from "redux-persist/lib/persistStore";
import { setAuthorizationToken } from "./config/axios";
import { routes } from "./Routes";
import configureStore from "./store/configureStore";
// import "./tronweb.js";
import Contract_obj from "./getTronweb.js";
import "./metacoin-config.js";
import browserHistory from "../src/history";
import "../src/styles/globals.css";
import Wrapped from "./container/Wrapped";
const Landing = lazy(() => import("./container/Landing"));
const Ambassador = lazy(() => import("./container/Ambassador"));

const Exchange = lazy(() => import("./container/Exchange"));
const ExchangeEceto = lazy(() => import("./container/ExchangeEceto"));
const ExchangeBceto = lazy(() => import("./container/ExchangeBceto"));

const Faq = lazy(() => import("./container/faq"));
const Referral = lazy(() => import("./container/Referral"));
const Whitepaper = lazy(() => import("./container/Whitepaper"));

const Tutorial = lazy(() => import("./container/tutorial"));
const Social = lazy(() => import("./container/social"));
const Animated = lazy(() => import("./components/AnimatedButton"));
const Test = lazy(() => import("./container/Test"));

const store = configureStore(window.__REDUX_STATE__);
const props = {
  store,
};
class App extends Component {
  constructor(appProps) {
    super(appProps);
    this.state = { rehydrated: false };
  }
  componentWillMount() {
    persistStore(
      store,
      {
        storage: localForage,
        blacklist: [],
      },
      () => {
        // store.dispatch({
        //   type: TURN_ON_DOB_MODAL
        // });
        console.log("app remounted");
        const { user } = store.getState();
        setAuthorizationToken(user);
        this.setState({
          rehydrated: true,
        });
      }
    );
  }

  render() {
    if (!this.state.rehydrated) return null;
    // return <Provider store={store}>{routes(props)}</Provider>
    return (
      <Provider store={store}>
        <Router
          history={browserHistory}
          onUpdate={() => window.scrollTo(0, 0)}
          props={props}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/exchange" component={Exchange} />
              <Route exact path="/ceto/buy" component={Exchange} />
              <Route exact path="/eceto/buy" component={ExchangeEceto} />
              <Route exact path="/bceto/buy" component={ExchangeBceto} />
              {/* <Route exact path="/:token/buy" component={Exchange} /> */}
              <Route exact path="/sell" component={Exchange} />
              <Route exact path="/ceto/sell" component={Exchange} />
              <Route exact path="/eceto/sell" component={ExchangeEceto} />
              <Route exact path="/bceto/sell" component={ExchangeBceto} />
              <Route exact path="/withdraw" component={Exchange} />
              <Route exact path="/ceto/withdraw" component={Exchange} />
              <Route exact path="/eceto/withdraw" component={ExchangeEceto} />
              <Route exact path="/bceto/withdraw" component={ExchangeBceto} />
              <Route exact path="/transfer" component={Exchange} />
              <Route exact path="/ceto/transfer" component={Exchange} />
              <Route exact path="/eceto/transfer" component={ExchangeEceto} />
              <Route exact path="/bceto/transfer" component={ExchangeBceto} />
              <Route exact path="/transactions" component={Exchange} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/referral" component={Referral} />
              <Route exact path="/whitepaper" component={Whitepaper} />
              <Route exact path="/tutorial" component={Tutorial} />
              <Route exact path="/social" component={Social} />
              <Route exact path="/test" component={Animated} />
              <Route exact path="/ambassador" component={Ambassador} />
              <Route exact path="/tester" component={Test} />
              <Route
                path="/eceto-ref/:ref_name"
                render={props => {
                  return <Landing {...props} />;
                }}
              />
              <Route
                path="/ceto-ref/:ref_name"
                render={props => {
                  return <Landing {...props} />;
                }}
              />{" "}
              <Route
                path="/bceto-ref/:ref_name"
                render={props => {
                  return <Landing {...props} />;
                }}
              />
              <Route></Route>
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    );
  }
}

hydrate(<App />, document.getElementById("root"));

// {           //  <h1> {`${props.name}`}</h1>
//  <img className="img" src="/images/pic.jpg" alt="bike" />}
