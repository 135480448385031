import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import ConnectionModal from "../components/connectionpopup";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";

import Actionbar from "../components/actionbar/actionbar";
import Navbar from "../components/navbar/navbar.component";
import SubNavbar from "../components/sub-navbar/sub-navbar.component";
import Footer from "../components/footer/footer.component";
import Buysell from "../components/buyselltokens/buysell";
import Currencies from "../components/currencies/currencies";
import Reinvestdividends from "../components/reinvestdividends/reinvestdividends";
import Holdings from "../components/holdings/holdings.component";
import Transactions from "../components/recent-transactions/recent-transactions.component";
import Withinv from "../components/withinv/withinv";
import Refer from "../components/Refer/Refer";
import styles from "../styles/Referral.module.scss";
import AlertDialog from "../components/metamaskAlert";
import { connect } from "react-redux";
import * as actions from "../actions";
import Contract_obj from "../getTronweb";
import contract_img from "../public/TRX in contract - Icon.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomLoader from "../components/Loader.js";
import metacoinConfig from "../metacoin-config.js";
import web3_obj from "../getWeb3";
import LinearProgress from "@material-ui/core/LinearProgress";

import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import ArrowInButton from "../public/herosection-button-icon.svg";
import Banner from "../components/banner";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Referral = props => {
  const [state, setstate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [balance, setBalance] = useState();
  const [ref_income, setRefIncome] = useState();
  const [ref_balance, setRefBalance] = useState();
  const [connectModal, setConnectModal] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [scrolling, setScrolling] = useState("up");
  const [txnLoading, setTxnLoading] = useState(false);
  const [blockchain, setBlockchain] = useState("trx");

  const [contractBalance, setContractBalance] = useState();
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );

  const changeState = num => {
    setstate(num);
  };
  useEffect(() => {
    if (txnLoading == true) {
      window.scrollTo(0, 0);
    }
  }, [txnLoading]);
  const copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
  };

  const fetchData = async () => {
    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : window.tronWeb.fullNode.host == "https://api.trongrid.io"
          ? "mainnet"
          : "private"
      ));

    if (Contract_obj.tronWebProvider) {
      Contract_obj.tronWebProvider &&
        (await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.contractAddress)
          .then(result => {
            setContractBalance(result);
          }));
      Contract_obj.tronWebProvider &&
        (await Contract_obj.tronWebProvider.trx
          .getBalance(Contract_obj.accounts[0])
          .then(result => {
            setBalance(result);
          }));

      Contract_obj.contract_instace &&
        (await Contract_obj.contract_instace
          .getReferralBalance()
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              console.log(res.toString(), "ref", res);
              setRefBalance(res[0].toString());
              setRefIncome(res[1].toString());

              setLoading(false);
            }
          }));
    }
    // else{
    //   setLoading(false)
    //   setOpen(true)
    // }
  };
  const ethData = async () => {
    await web3_obj.init();
    console.log("ethhh");

    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 1 || web3_obj.networkId == 3)
    ) {
      var referral_ = await web3_obj.contract_instace.methods
        .getReferralBalance()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(referral_, "referral_");
      setRefBalance(referral_[0].toString());
      setRefIncome(referral_[1].toString());
    }
    setLoading(false);
  };
  const bnbData = async () => {
    await web3_obj.init();
    console.log("ethhh");

    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var referral_ = await web3_obj.contract_instace.methods
        .getReferralBalance()

        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(referral_, "referral_ bnb");
      setRefBalance(referral_[0].toString());
      setRefIncome(referral_[1].toString());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.utils.blockchain == "trx") {
      var obj = setInterval(async () => {
        if (
          Contract_obj.contract_instace &&
          window.tronWeb &&
          window.tronWeb.defaultAddress.base58 &&
          (window.tronWeb.fullNode.host == metacoinConfig["mainnet"].fullHost ||
            window.tronWeb.fullNode.host == metacoinConfig["shasta"].fullHost)
        ) {
          clearInterval(obj);
          Contract_obj.contract_instace && fetchData();
        }
      }, 2);
      Contract_obj.contract_instace && fetchData();
    }
  }, []);
  useEffect(() => {
    console.log("hii", window);
    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message);

        if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // console.log("catch")
          // setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        } else {
          setUpdated(updated + 1);
          // setConnectModal(false);

          fetchData();
        }
      }
    });
    document.addEventListener("readystatechange", event => {
      if (event.target.readyState == "complete") {
        console.log(window, event, "windowww");
        fetchData();
        setTimeout(() => {
          if (
            !window.tronWeb ||
            (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
          ) {
            console.log("not loaded");
            // setConnectModal(true);
            setLoading(false);
            setUpdated(updated + 1);
          } else if (
            window.tronWeb &&
            window.tronWeb.fullNode.host != metacoinConfig.fullHost
          ) {
            // console.log("catch")
            // setConnectionIssue(1);
            // setConnectModal(true);
            setLoading(false);
          }
        }, 3000);
      }
    });
    if (props.utils.blockchain == "trx") {
      document.addEventListener("readystatechange", event => {
        if (event.target.readyState == "complete") {
          console.log(window, event, "windowww");
          fetchData();
          setTimeout(() => {
            if (
              !window.tronWeb ||
              (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
            ) {
              console.log("not loaded");
              // setConnectModal(true);
              setLoading(false);
              setUpdated(updated + 1);
            } else if (
              window.tronWeb &&
              window.tronWeb.fullNode.host != metacoinConfig.fullHost
            ) {
              // console.log("catch")
              // setConnectionIssue(1);
              // setConnectModal(true);
              setLoading(false);
            }
          }, 3000);
        }
      });
    }
  }, [
    Contract_obj.tronWebProvider,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
    window.tronWeb && window.tronWeb.fullNode.host,
    window.tronWeb && window.tronWeb,
    window,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    var lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (window.scrollY == 0) {
          setScrolling("up");
        } else {
          if (st > lastScrollTop) {
            // downscroll code
            setScrolling("down");
          } else {
            // upscroll code
            setScrolling("up");
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
    if (props.utils.blockchain == "trx") {
      document.addEventListener("readystatechange", event => {
        console.log(event, "event");
        if (event.target.readyState == "complete") {
          console.log(window, event, "windowww");
          // fetchData();
          var obj = setInterval(async () => {
            if (
              window.tronWeb &&
              window.tronWeb.defaultAddress.base58 &&
              window.tronWeb.fullNode.host == metacoinConfig.fullHost
            ) {
              clearInterval(obj);
              fetchData();
            }
          }, 2);
          setTimeout(() => {
            if (
              !window.tronWeb ||
              (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
            ) {
              console.log("not loaded");
              // setConnectModal(true);
              setLoading(false);
            } else if (
              window.tronWeb &&
              window.tronWeb.fullNode.host != metacoinConfig.fullHost
            ) {
              // console.log("catch")
              // setConnectionIssue(1);
              // setConnectModal(true);
              setLoading(false);
            }
          }, 3000);
        }
      });
      setTimeout(() => {
        if (
          !window.tronWeb ||
          (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
        ) {
          console.log("not loaded");
          // setConnectModal(true);
          setLoading(false);
        } else if (
          window.tronWeb &&
          window.tronWeb.fullNode.host != metacoinConfig.fullHost
        ) {
          // console.log("catch")
          // setConnectionIssue(1);
          // setConnectModal(true);
          setLoading(false);
        }
      }, 3000);
      fetchData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      ethData();
    }
    setBlockchain(props.utils.blockchain);
  }, []);
  useEffect(() => {
    props.utils.blockchain == "eth" ? ethData() : fetchData();
    setBlockchain(props.utils.blockchain);
  }, [updated, props.utils.blockchain]);
  const customize_link = async (ref_account, customization) => {};

  return loading == false ? (
    <>
      {/* <AlertDialog></AlertDialog> */}
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
      />
      <Banner {...props}></Banner>

      <Navbar {...props} homepage={false} blockchain={blockchain} />
      <SubNavbar
        {...props}
        step={2}
        changeState={changeState}
        updated={updated}
        blockchain={blockchain}
      />
      <div
        className={styles.marketplaceContainer}
        style={{
          backgroundColor: blockchain == "eth" ? "#E8E8E8" : "#f1f3fc",
        }}
      >
        {txnLoading && <LinearProgress />}

        <Actionbar
          // contract={contract_instace}
          {...props}
          contractBalance={contractBalance}
          balance={balance && balance}
          updated={updated}
          flag={scrolling == "up" ? 1 : 0}
          page={0}
          blockchain={blockchain}
        />
        <div className={styles.middlediv}>
          <Refer
            customize_link={customize_link}
            balance={balance && balance}
            contract={Contract_obj}
            updated={updated}
            setUpdated={setUpdated}
            txnLoading={txnLoading}
            blockchain={blockchain}
            setTxnLoading={setTxnLoading}
            {...props}
          />
          <div className={styles.right}>
            <div
              className={styles.boxx}
              style={{
                backgroundColor: blockchain == "eth" ? "#EBEBEB" : "white",
              }}
            >
              <div className={styles.imgg}>
                <img src={contract_img} width="32" height="32" />
              </div>
              <div className={styles.side_box}>
                <span>Referral Income Earned</span>
                <span
                  style={{
                    color: blockchain == "trx" ? "#1FD0FF" : "#A53CFF",
                  }}
                >
                  {ref_income && (ref_income / factor).toFixed(4)}{" "}
                  {props.utils.blockchain.toUpperCase()}
                </span>
              </div>
            </div>
            <div
              className={styles.boxx}
              style={{
                backgroundColor: blockchain == "eth" ? "#EBEBEB" : "white",
              }}
            >
              <div className={styles.imgg}>
                <img src={contract_img} width="32" height="32" />
              </div>
              <div className={styles.side_box}>
                <span>Referral Income Balance</span>
                <span
                  style={{
                    color: blockchain == "trx" ? "#1FD0FF" : "#A53CFF",
                  }}
                >
                  {ref_balance && (ref_balance / factor).toFixed(4)}{" "}
                  {props.utils.blockchain.toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.belowdiv}>
          <Transactions
            updated={0}
            setUpdated={setUpdated}
            {...props}
            blockchain={blockchain}
            theme={0}
            type={1}
          />
        </div>
      </div>
      <Footer blockchain={blockchain} />
    </>
  ) : (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      <CustomLoader></CustomLoader>
    </div>
  );
};

const mapStateToProps = state => {
  console.log(state, "state");
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Referral);
