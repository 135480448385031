import React, { useEffect, useState, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import axios from "axios";
import * as actions from "../../actions";
import { Input, Tooltip, Progress, InfoCircleOutlined, Button } from "antd";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { BuysellStyle } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import Buypopup from "../buypopup/buypopup";
import Transferpopup from "../transferpopup/transferpopup";
import { connect } from "react-redux";
import Contract_obj from "../../getTronweb";
import Sellpopup from "../sellpopup";
import AlertDialogSlide from "../infoBox";
import input_icon from "../../public/input_icon.png";
import convert_icon from "../../public/convert.png";
import styless from "./progress.module.scss";
import metacoinConfig from "../../metacoin-config.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { notification } from "antd";
import TronGrid from "trongrid";
import ConnectionModal from "../connectionpopup";
import AnimatedButton from "../AnimatedButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import web3_obj from "../../getWeb3";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import white_arrow from "../../public/white_arrow.png";
import black_arrow from "../../public/black_arrow.png";
import convert_arrow_black from "../../public/convert_arrow_black.svg";
import black_eth from "../../public/black_eth.png";
import black_bnb from "../../public/black_bnb.png";
import axiosInstance from "../../config/axios";

import black_ceto from "../../public/black_ceto.png";
import black_button from "../../public/black_button.png";
import bnb_black from "../../public/bnb__black.png";

import ceto__purple from "../../public/ceto__purple.svg";
import eth__purple from "../../public/eth__purple.png";

import ceto__yellow from "../../public/ceto__yellow.png";
import bsc_yellow from "../../public/bsc_yellow.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Buysell = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  timer,
  txnLoading,
  setTxnLoading,
  ...props
}) => {
  const [crypto, setCrypto] = useState();
  const [cryptoToTokens, setCryptoToTokens] = useState();
  const [tokens, setTokens] = useState();
  const [cryptoPerToken, setCryptoPerToken] = useState();
  const [usd, setUsd] = useState();
  const [usdToSpend, setUsdToSpend] = useState(0);
  const [open, setmodal] = useState(false);
  const [openSell, setSell] = useState(false);
  const [loss, setLoss] = useState();
  const [openTransfer, setOpentransfer] = useState(false);
  const [receipeintAddress, setReceipeintAddress] = useState();
  const [progress, setProgress] = useState(0);
  const [ledger, setLedger] = useState([]);
  const [gantChart, setGantChart] = useState();
  const [totalLoss, setTotalLoss] = useState();
  const [ref_address, setRefAddress] = useState();
  const [amount, setAmount] = useState();
  const [recent, setRecent] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [trongrid, setTrongrid] = useState();
  const mounted = useRef();
  const [timestamp_arr, set_timestamp_arr] = useState([]);
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [err, handleCloseErr] = useState(false);
  const [err_msg, set_err_msg] = useState("Error");
  const [accBalance, setAccBalance] = useState();
  const [status, setStatus] = useState(0);
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;

  const handleClose = () => {
    setmodal(false);
  };

  const handleCloseTransfer = () => {
    setOpentransfer(false);
  };

  const fetchData = async force => {
    setProcessing(false);
    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : window.tronWeb.fullNode.host == "https://api.trongrid.io"
          ? "mainnet"
          : "private"
      ));
    console.log(Contract_obj, "Contract_obj");
    setUsdToSpend(null);

    setCrypto(null);
    setCryptoToTokens(null);
    setAmount(null);
    window.tronWeb &&
      axiosInstance
        .get(
          `/api/cache/?function=myTokens&expiry=30&force=${force}&network=${
            window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
              ? "shasta"
              : window.tronWeb.fullNode.host == "https://api.trongrid.io"
              ? "mainnet"
              : metacoinConfig["private"].fullHost
          }&contract_address=${
            metacoinConfig["private"].contractAddressBase
          }&sender_address=${
            Contract_obj.tronWebProvider.defaultAddress.base58
          }`,
          {
            timeout: 50000,
          }
        )
        .then(resp => {
          console.log(resp.data.data, "ressp");
          var data = resp.data.data;
          setTokens(data.toString());
        })
        .catch(err => {
          console.log(err);
        });

    console.log("nckd");
    window.tronWeb &&
      axiosInstance
        .get(
          `/api/cache/?function=getCursor&expiry=30&force=${force}&network=${
            window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
              ? "shasta"
              : window.tronWeb.fullNode.host == "https://api.trongrid.io"
              ? "mainnet"
              : metacoinConfig["private"].fullHost
          }&contract_address=${
            metacoinConfig["private"].contractAddressBase
          }&sender_address=${
            Contract_obj.tronWebProvider.defaultAddress.base58
          }`,
          {
            timeout: 50000,
          }
        )
        .then(async resp => {
          var res = resp.data.data;
          if (res) {
            console.log(res, typeof res, "cursor");
            var start = parseInt(res[0].toString());
            var end = parseInt(res[1].toString());
          }

          var temp = [];
          var i = start;
          var gant_chart = {
            0: { value: 0, days: 0, percent: 0 },
            25: { value: 0, days: 0, percent: 0 },
            50: { value: 0, days: 0, percent: 0 },
            75: { value: 0, days: 0, percent: 0 },
          };

          for (i = start; i < end; i++) {
            // console.log(start, end, "ncdncld");

            window.tronWeb &&
              axiosInstance
                .get(
                  `/api/cache/?function=getTimestampedBalanceLedger&arguments=${i}&expiry=30&force=${force}&network=${
                    window.tronWeb.fullNode.host ==
                    "https://api.shasta.trongrid.io"
                      ? "shasta"
                      : window.tronWeb.fullNode.host ==
                        "https://api.trongrid.io"
                      ? "mainnet"
                      : metacoinConfig["private"].fullHost
                  }&contract_address=${
                    Contract_obj.contractAddress
                  }&sender_address=${Contract_obj.accounts[0]}`,
                  {
                    timeout: 50000,
                  }
                )
                .then(response => {
                  console.log(response, "smlcdnc");
                  var res = response.data.data;

                  if (res) {
                    // console.log(res, "gghg");
                    //store value , valueSOld ,   timestamp
                    if (!timestamp_arr.includes(res[1].toString())) {
                      temp.push({
                        value: res[0].toString(),
                        valueSold: res[2].toString(),
                        timestamp: res[1].toString(),
                      });
                      let temp_timestamp = timestamp_arr;
                      temp_timestamp.push(temp.timestamp);
                      set_timestamp_arr(temp_timestamp);
                      setLedger(temp);
                    }
                    if (temp.length == end - start) {
                      var today = parseInt(
                        new Date().getTime().toString().slice(0, -3)
                      );
                      temp.forEach(val => {
                        if (today - val.timestamp >= 2592000) {
                          gant_chart["0"].value += val.value - val.valueSold;
                        } else if (today - val.timestamp >= 1728001) {
                          gant_chart["25"].value += val.value - val.valueSold;
                        } else if (today - val.timestamp >= 864001) {
                          gant_chart["50"].value += val.value - val.valueSold;
                          gant_chart["50"].days = Math.max(
                            today - val.timestamp,
                            gant_chart["50"].days
                          );
                        } else {
                          gant_chart["75"].value += val.value - val.valueSold;
                          gant_chart["75"].days = Math.max(
                            today - val.timestamp,
                            gant_chart["75"].days
                          );
                        }
                      });

                      setGantChart(gant_chart);
                    }
                  }
                });
          }
          // setLedger(temp)
        });

    var ref_name_stored = localStorage.getItem("ceto_ref_name");

    if (ref_name_stored) {
      var str = "0x";
      var i;
      var ref_name = ref_name_stored;
      if (ref_name.length != 34) {
        for (i = 0; i < ref_name.length; i++) {
          str = str.concat(ref_name.charCodeAt(i).toString(16));
        }
        str = str.padEnd(66, "0");
        (await Contract_obj.contract_instace) &&
          Contract_obj.contract_instace
            .getReferralAddressForName(str)
            .call({
              from: Contract_obj.accounts[0],
              feeLimit: Contract_obj.feeLimit,
            })
            .then(function (res) {
              if (res) {
                setRefAddress(res.toString());
              }
            });
      } else {
        setRefAddress(ref_name);
      }
    }
  };
  const ethData = async () => {
    setProcessing(false);

    setCrypto(null);
    setCryptoToTokens(null);
    setAmount(null);
    setUsdToSpend(null);

    if (web3_obj.contract_instace && web3_obj.accounts[0][0]) {
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(my_tokens, "eth-token");
      setTokens(parseInt(my_tokens));

      var cursor = await web3_obj.contract_instace.methods
        .getCursor()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      var start = parseInt(cursor[0].toString());
      var end = parseInt(cursor[1].toString());
      console.log("cursor-eth", start, end);

      var temp = [];
      var i = start;
      var gant_chart = {
        0: { value: 0, days: 0, percent: 0 },
        25: { value: 0, days: 0, percent: 0 },
        50: { value: 0, days: 0, percent: 0 },
        75: { value: 0, days: 0, percent: 0 },
      };
      for (i = start; i < end; i++) {
        var res = await web3_obj.contract_instace.methods
          .getTimestampedBalanceLedger(i)

          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        if (res) {
          //store value , valueSOld ,   timestamp
          if (!timestamp_arr.includes(res[1].toString())) {
            temp.push({
              value: res[0].toString(),
              valueSold: res[2].toString(),
              timestamp: res[1].toString(),
            });
            let temp_timestamp = timestamp_arr;
            temp_timestamp.push(temp.timestamp);
            set_timestamp_arr(temp_timestamp);
            setLedger(temp);
          }
          if (temp.length == end - start) {
            var today = parseInt(new Date().getTime().toString().slice(0, -3));
            temp.forEach(val => {
              if (today - val.timestamp >= 2592000) {
                gant_chart["0"].value += val.value - val.valueSold;
              } else if (today - val.timestamp >= 1728001) {
                gant_chart["25"].value += val.value - val.valueSold;
              } else if (today - val.timestamp >= 864001) {
                gant_chart["50"].value += val.value - val.valueSold;
                gant_chart["50"].days = Math.max(
                  today - val.timestamp,
                  gant_chart["50"].days
                );
              } else {
                gant_chart["75"].value += val.value - val.valueSold;
                gant_chart["75"].days = Math.max(
                  today - val.timestamp,
                  gant_chart["75"].days
                );
              }
              // console.log(gant_chart, "chart", today - val.timestamp);
            });

            setGantChart(gant_chart);
          }
        }
      }
      var ref_name_stored = localStorage.getItem(
        props.blockchain == "bnb" ? "bceto_ref_name" : "eceto_ref_name"
      );

      if (ref_name_stored) {
        var str = "0x";
        var i;
        var ref_name = ref_name_stored;
        if (ref_name.length != 34) {
          for (i = 0; i < ref_name.length; i++) {
            str = str.concat(ref_name.charCodeAt(i).toString(16));
          }
          str = str.padEnd(66, "0");

          var ref_address = await web3_obj.contract_instace.methods
            .getReferralAddressForName(str)
            .call({
              from: web3_obj.accounts[0][0],
              gas: Contract_obj.feeLimit,
            });

          setRefAddress(ref_address);
        } else {
          setRefAddress(ref_name);
        }
      }
    }
  };
  useEffect(() => {
    if (props.utils.blockchain == "trx") {
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          // console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            (window.tronWeb.fullNode.host ==
              metacoinConfig["mainnet"].fullHost ||
              window.tronWeb.fullNode.host == metacoinConfig["shasta"].fullHost)
          ) {
            window.tronWeb &&
              window.tronWeb.defaultAddress.base58 &&
              Contract_obj.accounts[0] &&
              fetchData(0);
          }
        }
      });
    }
    Contract_obj.contract_instace &&
    (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb")
      ? ethData()
      : window.tronWeb &&
        window.tronWeb.defaultAddress.base58 &&
        Contract_obj.accounts[0] &&
        fetchData(0);
  }, []);

  useEffect(() => {
    setGantChart({
      0: { value: 0, days: 0, percent: 0 },
      25: { value: 0, days: 0, percent: 0 },
      50: { value: 0, days: 0, percent: 0 },
      75: { value: 0, days: 0, percent: 0 },
    });
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );

    props.utils.blockchain == "eth" || props.utils.blockchain == "bnb"
      ? ethData()
      : window.tronWeb &&
        window.tronWeb.defaultAddress.base58 &&
        Contract_obj.accounts[0] &&
        fetchData(1);
  }, [updated, theme, props.utils.blockchain]);
  // useEffect(() => {
  //   if (!mounted.current) {
  //     // do componentDidMount logic
  //     mounted.current = true;
  //   } else {
  //     // console.log("updated");

  //     Contract_obj.contract_instace &&
  //     (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb")
  //       ? ethData()
  //       : fetchData(0);
  //   }
  // }, [window.location.pathname]);

  const chechConnection = async v => {
    // console.log(Contract_obj, "Contract_obj__");
    if (props.utils.blockchain == "eth") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![1, 3].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        web3_obj.web3_instance.eth
          .getBalance(web3_obj.accounts[0][0])
          .then(res => {
            setAccBalance(res);
          });

        if (v == 1 && crypto) {
          setmodal(true);
        } else if (v == 2 && cryptoToTokens) {
          setSell(true);
        } else if (v == 3 && receipeintAddress && cryptoToTokens) {
          setOpentransfer(true);
        }
      }
    } else if (props.utils.blockchain == "bnb") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![97, 56].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        web3_obj.web3_instance.eth
          .getBalance(web3_obj.accounts[0][0])
          .then(res => {
            setAccBalance(res);
          });

        if (v == 1 && crypto) {
          setmodal(true);
        } else if (v == 2 && cryptoToTokens) {
          setSell(true);
        } else if (v == 3 && receipeintAddress && cryptoToTokens) {
          setOpentransfer(true);
        }
      }
    } else {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        setConnectModal(true);
      } else if (
        window.tronWeb &&
        window.tronWeb.fullNode.host != metacoinConfig["mainnet"].fullHost &&
        window.tronWeb.fullNode.host != metacoinConfig["shasta"].fullHost &&
        window.tronWeb.fullNode.host != metacoinConfig["private"].fullHost
      ) {
        setConnectionIssue(1);
        setConnectModal(true);
      } else {
        window.tronWeb &&
          (await window.tronWeb.trx
            .getBalance(Contract_obj.accounts[0])
            .then(result => {
              console.log(result, "balance", v, crypto);
              setAccBalance(result);
            }));
        if (v == 1 && crypto) {
          setmodal(true);
        } else if (v == 2 && cryptoToTokens) {
          setSell(true);
        } else if (v == 3 && receipeintAddress && cryptoToTokens) {
          setOpentransfer(true);
        }
      }
    }
  };

  const setDisabled = v => {
    if (
      !window.tronWeb ||
      (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
    ) {
      return false;
    } else if (
      window.tronWeb &&
      window.tronWeb.fullNode.host != metacoinConfig.fullHost
    ) {
      return false;
    } else if (processing) {
      return true;
    } else {
      if (v == 1 && crypto) {
        return false;
      } else if (v == 2 && cryptoToTokens) {
        return false;
      } else if (v == 3 && receipeintAddress && cryptoToTokens) {
        return false;
      } else {
        return true;
      }
    }
  };
  const buy = async () => {
    console.log(ref_address);
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        var crypto_ = (crypto * 1e18).toLocaleString("fullwide", {
          useGrouping: false,
        });

        setTxnLoading(true);

        await web3_obj.buyFunc(crypto_, ref_address);
        setTxnLoading(false);
        setStatus(1);
        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          localStorage.removeItem(
            props.utils.blockchain == "bnb"
              ? "bceto_ref_name"
              : "eceto_ref_name"
          );
        }, 1000);
      } catch (error) {
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    } else {
      try {
        await Contract_obj.buyFunc(crypto * factor, ref_address);
        // console.log("tron buy", Contract_obj, res);
        // setUpdated(updated + 1);
        setProcessing(true);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          localStorage.removeItem("ceto_ref_name");
        }, 5000);
      } catch (error) {
        // console.log("err");
        setProcessing(false);
        set_err_msg("You don't have sufficient balance in your account!");
        handleCloseErr(true);
      }
    }
  };

  const sell = async () => {
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        setTxnLoading(true);
        setSell(false);
        var cryptoToTokens_ = (
          cryptoToTokens * factor
        ).toLocaleString("fullwide", { useGrouping: false });

        await web3_obj.sellFunc(cryptoToTokens_);
        setTxnLoading(false);
        setStatus(1);

        // setUpdated(updated + 1);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          setProgress(0);
          setLoss("");
          setTotalLoss("");
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    } else {
      try {
        var res = await Contract_obj.sellFunc(cryptoToTokens);
        // console.log("sell", res);
        setSell(false);
        setProcessing(true);

        // setUpdated(updated + 1);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          setProgress(0);
          setLoss("");
          setTotalLoss("");
        }, 5000);
      } catch (error) {
        setProcessing(false);
        set_err_msg(
          `You don't have enough ${
            props.utils.blockchain == "eth"
              ? "ECETO"
              : props.utils.blockchain == "bnb"
              ? "BCETO"
              : "CETO"
          }!`
        );
        handleCloseErr(true);
      }
    }
  };
  const transfer = async () => {
    if (props.utils.blockchain == "eth" || props.utils.blockchain == "bnb") {
      try {
        setOpentransfer(false);
        setTxnLoading(true);
        var cryptoToTokens_ = (
          cryptoToTokens * factor
        ).toLocaleString("fullwide", { useGrouping: false });

        await web3_obj.transferFunc(receipeintAddress, cryptoToTokens_);
        setReceipeintAddress();
        setSell(false);
        setStatus(1);

        setTxnLoading(false);
        window.scrollTo(0, 0);

        // setUpdated(updated + 1);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          setProgress(0);
          setLoss("");
          setTotalLoss("");
          setReceipeintAddress("");
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    } else {
      try {
        var res = await Contract_obj.transferFunc(
          receipeintAddress,
          cryptoToTokens
        );
        // console.log("transferFunc", res);
        setReceipeintAddress();
        setProcessing(true);

        setOpentransfer(false);
        // setUpdated(updated + 1);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCrypto("");
          setCryptoToTokens("");
          setUsdToSpend("");
          setProgress(0);
          setLoss("");
          setTotalLoss("");
          setReceipeintAddress("");
        }, 5000);
      } catch (error) {
        setProcessing(false);
        set_err_msg(
          `You don't have enough ${
            props.utils.blockchain == "eth"
              ? "ECETO"
              : props.utils.blockchain == "bnb"
              ? "BCETO"
              : "CETO"
          }!`
        );
        handleCloseErr(true);
      }
    }
  };
  const buy_convert = async value => {
    setCrypto(value);
    console.log(value);

    if (
      (props.utils.blockchain == "eth" &&
        (web3_obj.networkId == 3 || web3_obj.networkId == 1)) ||
      (props.utils.blockchain == "bnb" &&
        (web3_obj.networkId == 97 || web3_obj.networkId == 56))
    ) {
      value = (value * 1e18).toLocaleString("fullwide", {
        useGrouping: false,
      });

      var tokens_received =
        web3_obj.contract_instace &&
        (await web3_obj.contract_instace.methods
          .calculateTokensReceived(value.toString())
          .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit }));
      console.log(tokens_received);
      setCryptoToTokens(parseInt(tokens_received));
      setUsdToSpend(((value * props.utils.currencies.USD) / factor).toFixed(2));
    } else if (props.utils.blockchain == "trx") {
      value = value * factor;
      console.log("ncckl nl", Contract_obj.contract_instace, Contract_obj);
      Contract_obj.contract_instace &&
        (await Contract_obj.contract_instace
          .calculateTokensReceived(value)
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
            callValue: Contract_obj.callValue || 0,
          })
          .then(function (res) {
            if (res) {
              console.log(res.toString(), "CETO TOKENS");
              setCryptoToTokens(res.toString());
              setUsdToSpend(
                ((value * props.utils.currencies.USD) / factor).toFixed(2)
              );
            }
          }));
    }
  };

  const convert_ceto = async value => {
    setCryptoToTokens(value);
    if (
      (props.utils.blockchain == "eth" &&
        (web3_obj.networkId == 3 || web3_obj.networkId == 1)) ||
      (props.utils.blockchain == "bnb" &&
        (web3_obj.networkId == 97 || web3_obj.networkId == 56))
    ) {
      if (value > tokens / factor) {
        set_err_msg(
          `You don't have enough ${
            props.utils.blockchain == "eth"
              ? "ECETO"
              : props.utils.blockchain == "bnb"
              ? "BCETO"
              : "CETO"
          }!`
        );
        handleCloseErr(true);
      } else {
        set_err_msg("");
        handleCloseErr(false);
      }
      if (value && value * factor < tokens && value > 0 && value != "") {
        value = (value * factor).toLocaleString("fullwide", {
          useGrouping: false,
        });
        console.log(value);
        if (web3_obj.contract_instace) {
          var tron_received = await web3_obj.contract_instace.methods
            .calculateEthereumReceived(value.toString())
            .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
          console.log(tron_received, "tron_received");
          setCrypto(tron_received);
          setUsdToSpend(
            ((value * props.utils.currencies.USD) / factor).toFixed(2)
          );

          var av_penalty = await web3_obj.contract_instace.methods
            .calculateAveragePenalty(value.toString(), web3_obj.accounts[0][0])
            .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
          console.log(av_penalty, "av_penalty");
          if (av_penalty) {
            var tron = await web3_obj.contract_instace.methods
              .tokensToEthereum_(value.toString())

              .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
            console.log(tron, "tron");
            setAmount(tron);
            var penalty =
              parseInt(av_penalty.toString()) * parseInt(tron.toString());
            penalty = penalty / 100;
            setTotalLoss(penalty);
            setLoss(av_penalty.toString());
          }
          var i = 0;
          value = parseInt(value);
          var gant_chart = gantChart;
          while (value > 0 && i <= 75) {
            if (gant_chart[i.toString()].value > 0) {
              gant_chart[i.toString()].percent =
                (value / gant_chart[i.toString()].value) * 100;

              value = value - gant_chart[i.toString()].value;
            } else {
              gant_chart[i.toString()].percent = 100;
            }
            i = i + 25;
          }

          var progress_ =
            gant_chart["0"].percent / 4 +
            gant_chart["25"].percent / 4 +
            gant_chart["50"].percent / 4 +
            gant_chart["75"].percent / 4;

          setProgress(progress_);
        }
      } else {
        setCryptoToTokens(value);
        setProgress(0);
        setCrypto();
        setUsdToSpend();
        setLoss();
        setTotalLoss();
      }
    } else if (
      props.utils.blockchain == "trx" &&
      Contract_obj.contract_instace
    ) {
      console.log(tokens, value);
      if (value > tokens / factor) {
        set_err_msg(
          `You don't have enough ${
            props.utils.blockchain == "eth"
              ? "ECETO"
              : props.utils.blockchain == "bnb"
              ? "BCETO"
              : "CETO"
          }!`
        );
        handleCloseErr(true);
      } else {
        set_err_msg("");
        handleCloseErr(false);
      }
      if (value && value * factor < tokens && value > 0 && value != "") {
        // console.log("value", value);

        value = value * factor;

        await Contract_obj.contract_instace
          .calculateTronReceived(value)
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              setCrypto(res.toString());
              setUsd(
                (
                  (parseInt(res.toString()) / factor) *
                  props.utils.currencies.USD
                ).toFixed(2)
              );
            }
          });
        await Contract_obj.contract_instace
          .calculateAveragePenalty(value, Contract_obj.accounts[0])
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(function (res) {
            if (res) {
              Contract_obj.contract_instace
                .tokensToTron_(value)
                .call({
                  from: Contract_obj.accounts[0],
                  feeLimit: Contract_obj.feeLimit,
                })
                .then(function (tron) {
                  setAmount(tron);
                  var penalty =
                    parseInt(res.toString()) * parseInt(tron.toString());
                  penalty = penalty / 100;
                  setTotalLoss(penalty);
                });
              setLoss(res.toString());
            }
          });
        var i = 0;
        value = parseInt(value);
        var gant_chart = gantChart;
        while (value > 0 && i <= 75) {
          if (gant_chart[i.toString()].value > 0) {
            gant_chart[i.toString()].percent =
              (value / gant_chart[i.toString()].value) * 100;

            value = value - gant_chart[i.toString()].value;
          } else {
            gant_chart[i.toString()].percent = 100;
          }
          i = i + 25;
        }

        var progress_ =
          gant_chart["0"].percent / 4 +
          gant_chart["25"].percent / 4 +
          gant_chart["50"].percent / 4 +
          gant_chart["75"].percent / 4;

        setProgress(progress_);
      } else {
        setCryptoToTokens(value);
        setProgress(0);
        setCrypto();
        setUsdToSpend();
        setLoss();
        setTotalLoss();
      }
    }
    if (value.length == 0 || value == null) {
      setProgress(0);
    }
  };

  useEffect(() => {
    // console.log(progress, cryptoToTokens, typeof cryptoToTokens);
    if (cryptoToTokens == "") {
      setProgress(0);
      setLoss();
      setTotalLoss();
      setCrypto("");
    }
  }, [progress]);

  const toDays = sec => {
    if (sec == 0) {
      return 0;
    }
    return Math.round((2592000 - sec) / (24 * 3600));
  };
  const convert_ = async value => {
    console.log(value, "nvkfl");

    if (value > tokens / factor) {
      set_err_msg(
        `You don't have enough ${
          props.utils.blockchain == "eth"
            ? "ECETO"
            : props.utils.blockchain == "bnb"
            ? "BCETO"
            : "CETO"
        }!`
      );
      handleCloseErr(true);
    } else {
      set_err_msg("");
      handleCloseErr(false);
    }
    if (
      (props.utils.blockchain == "eth" &&
        (web3_obj.networkId == 3 || web3_obj.networkId == 1)) ||
      (props.utils.blockchain == "bnb" &&
        (web3_obj.networkId == 97 || web3_obj.networkId == 56))
    ) {
      try {
        setCryptoToTokens(value);
        value = (value * 1e18).toLocaleString("fullwide", {
          useGrouping: false,
        });
        console.log(value, "nvkfl");
        if (web3_obj.contract_instace) {
          var tron_received = await web3_obj.contract_instace.methods
            .calculateEthereumTransferred(value)
            .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });
          setCrypto(tron_received);

          var tron = await web3_obj.contract_instace.methods
            .tokensToEthereum_(tron_received)
            .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

          setUsdToSpend(
            (parseInt(tron) * props.utils.currencies.USD).toFixed(2)
          );
        }
      } catch {
        setProgress(0);
        setCrypto(null);
        setUsdToSpend(0);
        setLoss();
        setTotalLoss();
      }
    } else if (props.utils.blockchain == "trx") {
      try {
        setCryptoToTokens(value);
        value = value * factor;

        await Contract_obj.contract_instace
          .calculateTronTransferred(value)
          .call({
            from: Contract_obj.accounts[0],
            feeLimit: Contract_obj.feeLimit,
          })
          .then(async res => {
            if (res) {
              setCrypto(res.toString());
              await Contract_obj.contract_instace
                .tokensToTron_(res.toString())
                .call({
                  from: Contract_obj.accounts[0],
                  feeLimit: Contract_obj.feeLimit,
                })
                .then(function (tron) {
                  setUsdToSpend(
                    (
                      parseInt(tron.toString()) * props.utils.currencies.USD
                    ).toFixed(2)
                  );
                });
            }
          });
      } catch {
        setProgress(0);
        setCrypto(null);
        setUsdToSpend(0);
        setLoss();
        setTotalLoss();
      }
    }
  };

  const getIconLeft = () => {
    if (theme <= 1) {
      if (props.blockchain == "trx") {
        return "https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg";
      } else if (props.blockchain == "eth") {
        return black_eth;
      } else {
        return black_bnb;
      }
    } else {
      if (props.blockchain == "trx") {
        return input_icon;
      } else if (props.blockchain == "eth") {
        return ceto__purple;
      } else {
        return ceto__yellow;
      }
    }
  };
  const getIconRight = () => {
    if (theme <= 1) {
      if (props.blockchain == "trx") {
        return input_icon;
      } else {
        return black_ceto;
      }
    } else if (theme == 2) {
      if (props.blockchain == "trx") {
        return "https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg";
      } else if (props.blockchain == "eth") {
        return eth__purple;
      } else {
        return bsc_yellow;
      }
    } else {
      if (props.blockchain == "trx") {
        return input_icon;
      } else if (props.blockchain == "eth") {
        return ceto__purple;
      } else {
        return ceto__yellow;
      }
    }
  };
  return (
    <div>
      <BuysellStyle
        theme={theme}
        width={props.width && props.width}
        blockchain={props.blockchain}
      >
        <div className={`buysell_container ${theme > 1 ? "newtheme" : ""}`}>
          <div className="upper">
            <span
              className="light"
              style={{
                color:
                  props.utils.blockchain == "bnb" && theme > 1
                    ? "black"
                    : props.utils.blockchain == "bnb"
                    ? "#EDC201"
                    : "white",
              }}
            >
              {theme <= 1 ? "Buy" : theme == 2 ? "Sell" : "Transfer"}{" "}
              {props.utils.blockchain == "eth"
                ? "ECETO"
                : props.utils.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}
            </span>
            <span className="lmobile">
              {theme <= 1 ? "Buy" : theme == 2 ? "Sell" : "Transfer"}{" "}
              {props.utils.blockchain == "eth"
                ? "ECETO"
                : props.utils.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}
            </span>
          </div>
          <div className="flex1">
            <div className="txt11">
              Enter{" "}
              {theme <= 1
                ? props.utils.blockchain.toUpperCase()
                : props.utils.blockchain == "eth"
                ? "ECETO"
                : props.utils.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}{" "}
              Value
            </div>
            <div className="txt11 right ss">
              {theme <= 1
                ? `${
                    props.utils.blockchain == "eth"
                      ? "ECETO"
                      : props.utils.blockchain == "bnb"
                      ? "BCETO"
                      : "CETO"
                  } You Get`
                : theme == 2
                ? "Amount You Get"
                : "They Get"}
            </div>
          </div>
          <div className="convert">
            <Input
              className="inputText"
              prefix={<img src={getIconLeft()} />}
              type="number"
              value={theme <= 1 ? crypto : cryptoToTokens}
              onChange={e =>
                theme <= 1
                  ? buy_convert(e.target.value)
                  : theme == 2
                  ? convert_ceto(e.target.value)
                  : convert_(e.target.value)
              }
            />

            <button
              className={`convertbutton`}
              style={{
                backgroundColor:
                  props.blockchain == "eth" && theme > 1
                    ? "black"
                    : props.blockchain == "bnb" && theme > 1 && "#6819A8",
              }}
            >
              <img
                src={
                  props.utils.blockchain == "trx" || theme > 1
                    ? convert_icon
                    : convert_arrow_black
                }
              />
            </button>
            <Input
              className="resultText"
              suffix={<img src={getIconRight()} />}
              type="number"
              value={
                theme <= 1
                  ? cryptoToTokens &&
                    (props.utils.blockchain == "eth"
                      ? (cryptoToTokens / factor).toFixed(6)
                      : (cryptoToTokens / factor).toFixed(6))
                  : crypto &&
                    (props.utils.blockchain == "eth"
                      ? (crypto / factor).toFixed(6)
                      : (crypto / factor).toFixed(6))
              }
            />
          </div>
          {theme <= 1 ? (
            <p className={`medium  rt`}>
              Approximately {usdToSpend} USD to Spend
            </p>
          ) : theme == 2 ? (
            <div classname={`loss_info`}>
              <span className="txt2">
                At loss of {loss && loss}% | Total Loss:{" "}
                {totalLoss && totalLoss / factor}{" "}
                {theme == 2
                  ? props.utils.blockchain.toUpperCase()
                  : props.utils.blockchain == "eth"
                  ? "ECETO"
                  : props.utils.blockchain == "bnb"
                  ? "BCETO"
                  : "CETO"}
              </span>
              <IconButton
                color="primary"
                style={{ padding: 0 }}
                onClick={() => setShowInfo(true)}
              >
                <HelpOutlineIcon
                  style={{
                    color: props.blockchain == "bnb" ? "black" : "#1FD0FF",
                    width: "18px",
                    height: "18px",
                    margin: "0 10px",
                  }}
                />
              </IconButton>
            </div>
          ) : (
            <p className={`medium  rt`}>
              Approximately {usdToSpend && (usdToSpend / factor).toFixed(2)} USD
              to transfer
            </p>
          )}
          {theme == 3 ? (
            <div className="receipeintdiv">
              <p className={`medium`}>Recipient Wallet</p>
              <input
                className="inputText"
                type="text"
                value={receipeintAddress}
                onChange={e => setReceipeintAddress(e.target.value)}
              />
            </div>
          ) : null}
          <div class="wrapper">
            <div class="bg">
              <div class="el"></div>
            </div>
          </div>
          {theme == 2 && (
            <div className="Block l">
              <Progress
                strokeColor={{
                  "0%": props.blockchain == "bnb" ? "#00273E" : "#AACAFB",
                  "25%": props.blockchain == "bnb" ? "#004791" : "#90DACC",
                  "50%": props.blockchain == "bnb" ? "#7440A0" : "#96F6AB",
                  "75%": props.blockchain == "bnb" ? "#D327A7" : "#FFE384",
                }}
                percent={progress}
                status="active"
              />
              <div className="abs">
                <div className="progress-block">
                  <div className="block">
                    <div className="txt1">Available Today</div>
                    <div className="txt2">
                      {gantChart &&
                        (props.utils.blockchain == "eth"
                          ? (gantChart["0"].value / factor).toFixed(2)
                          : (gantChart["0"].value / factor).toFixed(2))}{" "}
                      <span>
                        {props.utils.blockchain == "eth"
                          ? "ECETO"
                          : props.utils.blockchain == "bnb"
                          ? "BCETO"
                          : "CETO"}
                      </span>
                    </div>

                    <div className="txt3">100% today</div>
                  </div>
                  <div className="block">
                    <div className="txt1">25% Loss</div>
                    <div className="txt2">
                      {gantChart &&
                        (props.utils.blockchain == "eth"
                          ? (gantChart["25"].value / factor).toFixed(2)
                          : (gantChart["25"].value / factor).toFixed(2))}{" "}
                      <span>
                        {props.utils.blockchain == "eth"
                          ? "ECETO"
                          : props.utils.blockchain == "bnb"
                          ? "BCETO"
                          : "CETO"}
                      </span>
                    </div>
                    <div className="txt3">
                      100% in ~{gantChart && toDays(gantChart["25"].days)} days
                    </div>
                  </div>
                  <div className="block">
                    <div className="txt1">50% Loss</div>
                    <div className="txt2">
                      {gantChart &&
                        (props.utils.blockchain == "eth"
                          ? (gantChart["50"].value / factor).toFixed(2)
                          : (gantChart["50"].value / factor).toFixed(2))}{" "}
                      <span>
                        {props.utils.blockchain == "eth"
                          ? "ECETO"
                          : props.utils.blockchain == "bnb"
                          ? "BCETO"
                          : "CETO"}
                      </span>
                    </div>
                    <div className="txt3">
                      100% in ~{gantChart && toDays(gantChart["50"].days)} days
                    </div>
                  </div>
                  <div className="block">
                    <div className="txt1">75% Loss</div>
                    <div className="txt2">
                      {gantChart &&
                        (props.utils.blockchain == "eth"
                          ? (gantChart["75"].value / factor).toFixed(2)
                          : (gantChart["75"].value / factor).toFixed(2))}{" "}
                      <span>
                        {props.utils.blockchain == "eth"
                          ? "ECETO"
                          : props.utils.blockchain == "bnb"
                          ? "BCETO"
                          : "CETO"}
                      </span>
                    </div>
                    <div className="txt3">
                      100% in ~{gantChart && toDays(gantChart["75"].days)} days
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
          {theme == 2 && (
            <div className="btm l">
              <div className="chip">
                {theme == 2 ? "Selling" : "Transferring"}{" "}
                {gantChart && gantChart["0"].percent.toFixed()}%
              </div>
              <div className="chip">
                {theme == 2 ? "Selling" : "Transferring"}{" "}
                {gantChart && gantChart["25"].percent.toFixed()}%
              </div>
              <div className="chip">
                {theme == 2 ? "Selling" : "Transferring"}{" "}
                {gantChart && gantChart["50"].percent.toFixed()}%
              </div>

              <div className="chip">
                {theme == 2 ? "Selling" : "Transferring"}{" "}
                {gantChart && gantChart["75"].percent.toFixed()}%
              </div>
            </div>
          )}
          {theme <= 1 ? (
            props.blockchain == "trx" ? (
              <div
                id="button-wrappered"
                className={`${processing ? "checked" : ""}`}
                onClick={() => !processing && chechConnection(1)}
                style={{
                  cursor: setDisabled(1) && "not-allowed",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  {" "}
                  <defs>
                    {" "}
                    <filter id="goo">
                      {" "}
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="10"
                        result="blur"
                      />{" "}
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                        result="goo"
                      />{" "}
                      <feBlend in="SourceGraphic" in2="goo" />{" "}
                    </filter>{" "}
                  </defs>
                </svg>
                <p className={`${processing ? "submited circle" : "submited"}`}>
                  Buy Ceto{" "}
                  <img
                    className={`${processing ? "hideArrow" : ""}`}
                    src={arrow}
                  />
                </p>
                <div className="loader-wrapper">
                  <span className="loader yellow"></span>
                  <span className="loader pink"></span>
                </div>
                <span className="loader orange"></span>

                <div className="check-wrapper">
                  <svg
                    version="1.1"
                    width="65px"
                    height="38px"
                    viewBox="0 0 64.5 37.4"
                  >
                    <polyline
                      className="check"
                      points="5,13 21.8,32.4 59.5,5 "
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <GreenButton
                style={{
                  background: "#A53CFF",
                  color: "white",
                  marginTop: "37px",
                  cursor: setDisabled(1) && "not-allowed",
                }}
                onClick={() => !processing && chechConnection(1)}
              >
                Buy{" "}
                {props.utils.blockchain == "eth"
                  ? "ECETO"
                  : props.utils.blockchain == "bnb"
                  ? "BCETO"
                  : "CETO"}
                {processing ? (
                  <CircularProgress
                    width={28}
                    height={28}
                    style={{
                      width: "28px",
                      height: "28px",
                      marginLeft: "1rem",
                    }}
                  />
                ) : (
                  <img src={white_arrow} />
                )}
              </GreenButton>
            )
          ) : theme == 2 ? (
            props.blockchain == "trx" ? (
              <div
                id="button-wrappered"
                className={`${processing ? "checked" : ""}`}
                onClick={() => !processing && chechConnection(2)}
                style={{
                  cursor: setDisabled(1) && "not-allowed",
                  color: "white",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  {" "}
                  <defs>
                    {" "}
                    <filter id="goo">
                      {" "}
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="10"
                        result="blur"
                      />{" "}
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                        result="goo"
                      />{" "}
                      <feBlend in="SourceGraphic" in2="goo" />{" "}
                    </filter>{" "}
                  </defs>
                </svg>
                <p
                  style={{
                    background: "#009EE4",
                  }}
                  className={`${processing ? "submited circle" : "submited"}`}
                >
                  Sell{" "}
                  {props.utils.blockchain == "eth"
                    ? "ECETO"
                    : props.utils.blockchain == "bnb"
                    ? "BCETO"
                    : "CETO"}{" "}
                  <img
                    className={`${processing ? "hideArrow" : ""}`}
                    src={arrow2}
                  />
                </p>
                <div className="loader-wrapper">
                  <span className="loader blue"></span>
                  <span className="loader darkblue"></span>
                </div>
                <span className="loader white"></span>

                <div className="check-wrapper">
                  <svg
                    version="1.1"
                    width="65px"
                    height="38px"
                    viewBox="0 0 64.5 37.4"
                  >
                    <polyline
                      className="check"
                      points="5,13 21.8,32.4 59.5,5 "
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <GreenButton
                style={{
                  marginTop: "37px",
                  background: props.blockchain == "eth" ? "#E0E0E0" : "black",
                  color: props.blockchain == "bnb" ? "white" : "black",
                  cursor: setDisabled(1) && "not-allowed",
                }}
                onClick={() => !processing && chechConnection(2)}
              >
                Sell{" "}
                {props.utils.blockchain == "eth"
                  ? "ECETO"
                  : props.utils.blockchain == "bnb"
                  ? "BCETO"
                  : "CETO"}
                {processing ? (
                  <CircularProgress
                    width={28}
                    height={28}
                    style={{
                      width: "28px",
                      height: "28px",
                      marginLeft: "1rem",
                      color: "white",
                    }}
                  />
                ) : (
                  <img
                    src={props.blockchain == "eth" ? black_button : arrow2}
                  />
                )}
              </GreenButton>
            )
          ) : (
            theme == 3 &&
            (props.blockchain == "trx" ? (
              <div
                id="button-wrappered"
                className={`${processing ? "checked" : ""}`}
                onClick={() => !processing && chechConnection(3)}
                style={{
                  cursor: setDisabled(1) && "not-allowed",
                  color: "white",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  {" "}
                  <defs>
                    {" "}
                    <filter id="goo">
                      {" "}
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="10"
                        result="blur"
                      />{" "}
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                        result="goo"
                      />{" "}
                      <feBlend in="SourceGraphic" in2="goo" />{" "}
                    </filter>{" "}
                  </defs>
                </svg>
                <p
                  style={{
                    background: "#009EE4",
                  }}
                  className={`${processing ? "submited circle" : "submited"}`}
                >
                  Transfer{" "}
                  {props.utils.blockchain == "eth"
                    ? "ECETO"
                    : props.utils.blockchain == "bnb"
                    ? "BCETO"
                    : "CETO"}{" "}
                  <img
                    className={`${processing ? "hideArrow" : ""}`}
                    src={arrow2}
                  />
                </p>
                <div className="loader-wrapper">
                  <span className="loader yellow"></span>
                  <span className="loader pink"></span>
                </div>
                <span className="loader orange"></span>

                <div className="check-wrapper">
                  <svg
                    version="1.1"
                    width="65px"
                    height="38px"
                    viewBox="0 0 64.5 37.4"
                  >
                    <polyline
                      className="check"
                      points="5,13 21.8,32.4 59.5,5 "
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <GreenButton
                style={{
                  marginTop: "37px",
                  background: props.blockchain == "eth" ? "#E0E0E0" : "black",
                  color: props.blockchain == "bnb" ? "white" : "black",
                  cursor: setDisabled(1) && "not-allowed",
                }}
                onClick={() => !processing && chechConnection(3)}
              >
                Transfer{" "}
                {props.utils.blockchain == "eth"
                  ? "ECETO"
                  : props.utils.blockchain == "bnb"
                  ? "BCETO"
                  : "CETO"}
                {processing ? (
                  <CircularProgress
                    width={28}
                    height={28}
                    style={{
                      width: "28px",
                      height: "28px",
                      marginLeft: "1rem",
                      color: "white",
                    }}
                  />
                ) : (
                  <img
                    src={props.blockchain == "eth" ? black_button : arrow2}
                  />
                )}
              </GreenButton>
            ))
          )}
        </div>
      </BuysellStyle>
      <Buypopup
        open={open}
        handleClose={handleClose}
        crypto={crypto}
        cryptoToTokens={cryptoToTokens}
        buy={buy}
        usdToSpend={usdToSpend}
        accBalance={accBalance}
        factor={factor}
        blockchain={props.utils.blockchain}
      />
      <Transferpopup
        open={openTransfer}
        handleClose={handleCloseTransfer}
        transfer={transfer}
        receipeintAddress={receipeintAddress}
        cryptoToTokens={cryptoToTokens}
        totalLoss={totalLoss}
        crypto={crypto}
        factor={factor}
        accBalance={accBalance}
        blockchain={props.utils.blockchain}
      />
      <Sellpopup
        open={openSell}
        handleClose={setSell}
        cryptoToTokens={cryptoToTokens}
        crypto={crypto}
        usd={usdToSpend}
        penalty={totalLoss}
        sell={sell}
        factor={factor}
        tokens={tokens}
        amount={amount}
        timer={timer}
        accBalance={accBalance}
        blockchain={props.utils.blockchain}
      />
      <AlertDialogSlide
        theme={theme}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
      ></AlertDialogSlide>
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
        setConnectModal={setConnectModal}
        blockchain={props.utils.blockchain}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={err}
        autoHideDuration={6000}
        onClose={() => handleCloseErr(false)}
      >
        <Alert onClose={() => handleCloseErr(false)} severity="error">
          {err_msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={status == 1}
        onClose={() => setStatus(0)}
        autoHideDuration={6000}
        key={vertical + horizontal}
        className="notifs"
      >
        <div>
          <CheckCircleIcon
            style={{ color: "#10c983", marginRight: "20px" }}
          ></CheckCircleIcon>
          <span>Transaction completed!</span>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={status == 2}
        onClose={() => setStatus(0)}
        autoHideDuration={600000}
        key={vertical}
        className="notifs"
      >
        <div style={{ padding: "0px 16px" }}>
          <CancelIcon
            style={{ color: "#be0606", marginRight: "20px" }}
          ></CancelIcon>
          <span>Transaction failed!</span>
          <IconButton onClick={() => setStatus(0)}>
            <ClearIcon style={{ marginleft: "1 em" }}></ClearIcon>
          </IconButton>
        </div>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Buysell);
