import React, { useState, useEffect } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../src/actions";
// 3rd party libs.
import ReactHtmlParser from "react-html-parser";

// Material-UI
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  MobileStepper,
  useTheme,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
// Custom Comp.
import Navbar from "../components/navbar/navbar.component";
import CinemaDraftButton from "../components/cinema-draft-button/cinema-draft-button.component";
import Footer from "../components/footer/footer.component";

// Images
import arrow from "../public/Button - Icon.svg";

import ArrowInButton from "../public/herosection-button-icon.svg";
import LogoCyan from "../public/CETO-icon-cyan.svg";
import WisdomIllustration from "../public/wisdom-section-illustration.jpg";
import LoyaltySectionIllustration from "../public/loyalty-section-illustration.svg";
import TwitterIcon from "../public/twitter-icon.svg";
import DiscordIcon from "../public/discord-icon.svg";
import TelegramIcon from "../public/telegram-icon.svg";
import Elephant from "../public/hero-section-elephant.svg";
import convo from "../public/convo.png";
import arrow2 from "../public/Button - Icon.svg";
import token_value from "../public/Token Value.svg";
import roadmap from "../public/Aggressive Roadmap.svg";
import stability from "../public/Contract Stability.svg";
import transaction from "../public/Transaction.svg";
import left from "../public/left.png";
import right from "../public/right.png";
import down from "../public/down.png";
import scroll from "../public/scroll.png";
import eth from "../public/ethe.png";
import bnb from "../public/bnb.png";
import axiosInstance from "../config/axios";
import logo from "../public/PNG transparent file 03@2x.png";
import blue_arrow from "../public/blue_arrow.png";
import eceto_logo from "../public/ECETO-Logo.png";
import bceto_logo from "../public/BCETO-Logo.png";

// Mapped Data for landing page
import QUESTIONS_ARRAY from "./../assets/landing/questions";
import chat from "../public/chat_.png";
// Stylesheets
import "../styles/Landing.scss";
import getWeb3 from "../getWeb3";
import Contract_obj from "../getTronweb.js";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import metacoinConfig from "../metacoin-config";
import CustomLoader from "../components/Loader";
import Banner from "../components/banner";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "20vw",
    background: "#002E8C",
    borderRadius: "60px",
    fontFamily: "Poppins",
    margin: " 2vh auto 6vh",
    [theme.breakpoints.down("sm")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
    fontFamily: "Poppins",

    opacity: "0.5",
    paddingLeft: "0.7em",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Landing = props => {
  const [questionsArray, setQuestionsArray] = useState(QUESTIONS_ARRAY);
  const [activeCase, setCase] = useState(2);
  const [timer, setTimer] = useState();
  const [live, setLive] = useState(false);
  const [trxUsd, setTrxUsd] = useState();
  const [ethUsd, setEthUsd] = useState();
  const [bnbUsd, setBnbUsd] = useState();
  const [trxBalance, setTrxBalance] = useState();
  const [ethBalance, setEthBalance] = useState();
  const [bnbBalance, setBnbBalance] = useState();
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;
  // Function which toggles the question & answers
  const toggleQA = (e, idx) => {
    setQuestionsArray(questionsArray => {
      const dummyArr = questionsArray;

      dummyArr[idx].isOpen = !dummyArr[idx].isOpen;

      return [...dummyArr];
    });
  };

  const getContractInfo = async () => {
    axiosInstance
      .get(`/api/landing/page/data`)
      .then(resp => {
        console.log(resp.data.data, "resp");
        var data = resp.data.data;
        setTrxBalance(parseInt(data.trx_in_contract) / 1e6);
        setEthBalance(parseInt(data.eth_in_contract) / 1e18);
        setBnbBalance(parseInt(data.bnb_in_contract) / 1e18);
        setTotalAmount(parseInt(data.total_usd));
        // setTotalSupply(data.total_ceto.toString());

        setTrxUsd(parseInt(data.trx_per_ceto) / 1e6);
        setEthUsd(parseInt(data.eth_per_ceto) / 1e18);
        setBnbUsd(parseInt(data.bnb_per_ceto) / 1e18);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    var subscribed_ = localStorage.getItem("subscribed");
    setSubscribed(subscribed_);

    getContractInfo();
    const offset = 48600;
    const end_timestamp = 1612393200;

    var x = setInterval(function () {
      // Get today's date and time
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      // Find the distance between now and the count down date
      var distance = end_timestamp - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (60 * 60 * 24));
      var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((distance % (60 * 60)) / 60);
      var seconds = Math.floor(distance % 60);

      // Display the result in the element with id="demo"
      setTimer({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setTimer();
        setLive(true);
      }
    }, 1000);

    console.log(props.match.params.ref_name, "props", props);
    if (props.match.params.ref_name) {
      if (props.match.path == "/bceto-ref/:ref_name") {
        localStorage.setItem("bceto_ref_name", props.match.params.ref_name);
      } else if (props.match.path == "/eceto-ref/:ref_name") {
        localStorage.setItem("eceto_ref_name", props.match.params.ref_name);
      } else {
        localStorage.setItem("ceto_ref_name", props.match.params.ref_name);
      }
    }
  }, []);

  const Formatter = num => {
    console.log(num, typeof num);
    return Math.abs(num) > 999999
      ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
      : Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : (Math.sign(num) * Math.abs(num)).toFixed(2);
  };
  const handleChange = async e => {
    console.log(e);

    props.setCurrencies(e);

    console.log("kk");
    await props.setBlockchain(e);
    console.log("kk1");
    if (e == "eth") {
      props.history.push({ pathname: "/eceto/buy" });
    } else if (e == "bnb") {
      props.history.push({ pathname: "/bceto/buy" });
    } else {
      props.history.push({ pathname: "/ceto/buy" });
    }
  };

  const subscribe = e => {
    console.log("subscribe");
    setProcessing(true);
    e && e.preventDefault();
    axiosInstance
      .post("/api/subscribe/", { email: email })
      .then(res => {
        setStatus(1);
        setProcessing(false);
        setEmail("");
        localStorage.setItem("subscribed", true);
        setSubscribed(true);
      })
      .catch(error => {
        console.log("error", error);
        setProcessing(false);
      });
  };

  return loading ? (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      <CustomLoader></CustomLoader>
    </div>
  ) : (
    <div className="landingContainer">
      {/* Navbar and Hero Section */}
      <div className="navAndHeroSection">
        <Banner {...props}></Banner> {/* Navbar */}
        <Navbar {...props} homepage={true} page={100} />
        {/* Hero Section */}
        <div className="navAndHeroSection__hero">
          <span>Welcome to your crypto savings account.</span>
          <div className="boxx_1">
            <span className="T">
              <span className="t1">
                ${totalAmount && Formatter(totalAmount)}+
              </span>
              <span className="t2">Total Value Trusted</span>
            </span>
          </div>
          <span className="para">
            Where every deposit and withdrawal is <b>taxed 10%</b> <br></br>Fees
            are 100% distributed as <b>DIVIDENDS </b>to all tokenholders{" "}
            <br></br>Penalties apply for early withdrawal within 30 days
            <br></br>
            <b> Come reinvest with the BEST</b>
          </span>
          {/* {trxCeto && usdCeto && ( */}
          <span className="valuess">
            <div className="boxxx_">
              <div className="boxx_1">
                <span className="T">
                  <span className="t1">${trxUsd && trxUsd.toFixed(2)}</span>
                  <span className="t2">USD/CETO</span>
                </span>
                <span className="T">
                  <span className="t1">
                    {trxBalance && Formatter(trxBalance)}+
                  </span>
                  <span className="t2">TRX in Contract</span>
                </span>
              </div>
              <CinemaDraftButton
                className="button__"
                onClick={() => handleChange("trx")}
              >
                <img
                  alt="landing_imgs"
                  src={logo}
                  width="100px"
                  className="blockchain_logo"
                />
                Exchange
                <img alt="landing_imgs" src={blue_arrow} width="20px" />
              </CinemaDraftButton>
            </div>
            <hr className="vertical_hr l"></hr>

            <div className="boxxx_">
              <div className="boxx_1">
                <span className="T">
                  <span className="t1">${bnbUsd && bnbUsd.toFixed(2)}</span>
                  <span className="t2">USD/BCETO</span>
                </span>
                <span className="T">
                  <span className="t1">
                    {bnbBalance && Formatter(bnbBalance)}+
                  </span>
                  <span className="t2">BNB in Contract</span>
                </span>
              </div>
              <CinemaDraftButton
                className="button__"
                onClick={() => handleChange("bnb")}
              >
                <img
                  alt="landing_imgs"
                  src={bceto_logo}
                  width="100px"
                  className="blockchain_logo"
                />
                Exchange
                <img alt="landing_imgs" src={blue_arrow} width="20px" />
              </CinemaDraftButton>
              {/* <div className="live_a">Live for ambassadors!</div> */}
            </div>
            <hr className="vertical_hr l"></hr>

            <div className="boxxx_">
              <div className="boxx_1">
                <span className="T">
                  <span className="t1">${ethUsd && ethUsd.toFixed(2)}</span>
                  <span className="t2">USD/ECETO</span>
                </span>
                <span className="T">
                  <span className="t1">
                    {ethBalance && Formatter(ethBalance)}+
                  </span>
                  <span className="t2">ETH in Contract</span>
                </span>
              </div>
              <CinemaDraftButton
                className="button__"
                onClick={() => handleChange("eth")}
              >
                <img
                  alt="landing_imgs"
                  src={eceto_logo}
                  width="100px"
                  className="blockchain_logo"
                />
                Exchange
                <img alt="landing_imgs" src={blue_arrow} width="20px" />
              </CinemaDraftButton>
              {/* <div className="live_a">Live for ambassadors!</div> */}
            </div>
          </span>
          {/* )}{" "} */}
          <div className="flex-btns"></div>
          {!subscribed && (
            <>
              <div className="signip-txt">
                Sign up for email updates from the CETO Foundation
              </div>
              <form
                component="form"
                className={classes.root}
                onSubmit={e => subscribe(e)}
              >
                <InputBase
                  required
                  type="email"
                  className={classes.input}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter email address"
                  inputProps={{ "aria-label": "Enter email address" }}
                />
                {processing ? (
                  <CircularProgress
                    style={{
                      color: "white",
                    }}
                    className={classes.iconButton}
                  />
                ) : (
                  <IconButton
                    type="submit"
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                  >
                    <img src={ArrowInButton}></img>
                  </IconButton>
                )}
              </form>
            </>
          )}
          {/* <img src={scroll} className="scroller"></img> */}
        </div>
      </div>

      {/* Hello CETO */}
      <div className="helloCETO">
        <span>Why CETO?</span>
        <div className="box-0">
          <div className="box-1">
            <img className="box_icon" src={token_value}></img>
            <span className="box_txt">Token value increases with buys</span>
          </div>
          <div className="box-1">
            <img className="box_icon" src={transaction}></img>
            <span className="box_txt">Transactions = Dividends</span>
          </div>
          <div className="box-1">
            <img className="box_icon" src={stability}></img>
            <span className="box_txt">
              Contract stability due to 30 day withdrawal penalties
            </span>
          </div>
          <div className="box-1">
            <img className="box_icon" src={roadmap}></img>
            <span className="box_txt">
              Aggressive roadmap of high-volume transactional products for more
              dividends
            </span>
          </div>
        </div>
      </div>
      {/* Saddle Up */}
      <div className="saddleUp">
        <div>
          <span>Saddle Up</span>
          <CinemaDraftButton
            className="webshow"
            onClick={() => {
              props.history.replace({ pathname: "/whitepaper" });
            }}
            style={{ background: "#fff", color: "#002E8C", marginTop: "1.2em" }}
          >
            Whitepaper
            <img alt="landing_imgs" src={ArrowInButton} width="20px" />
          </CinemaDraftButton>
        </div>

        <div className="id">
          <span>
            <img alt="landing_imgs" src={LogoCyan} />
            Exchange your chosen coin (TRX, ETH, or BNB) for CETO through your
            TronLink, Metamask or compatible web wallet. It’s not as hard as you
            think.
          </span>
          <span>
            <img alt="landing_imgs" src={LogoCyan} />
            Every time someone buys CETO, the price of CETO goes up
            incrementally. Every time someone sells CETO, the price goes down by
            the same increment.
          </span>
          <span>
            <img alt="landing_imgs" src={LogoCyan} />
            When someone makes a transaction of any kind, 10% is taken out and
            distributed proportionally as dividends to EVERY tokenholder alike.
          </span>
          <span>
            <img alt="landing_imgs" src={LogoCyan} />
            Leave whenever you want. Just know if it’s within 30 days of your
            deposit, it’ll cost you:
          </span>
          <ul>
            <li>sell 0-9 days, -75% principal value</li>
            <li>sell 10-19 days, -50% principal value</li>
            <li>sell 20-29 days, -25% principal value</li>
            <li>
              sell 30+ days, <b>receive 100% of your principal value</b>
            </li>
          </ul>
        </div>
        <div className="mobileshow">
          <CinemaDraftButton
            onClick={() => {
              props.history.replace({ pathname: "/whitepaper" });
            }}
            style={{ background: "#fff", color: "#002E8C", marginTop: "1.2em" }}
          >
            Whitepaper
            <img alt="landing_imgs" src={arrow2} width="20px" />
          </CinemaDraftButton>
        </div>
      </div>

      {/* Hourglass */}
      <div className="hourglass l">
        <span>What's next?</span>
        <div>
          <div className="box-0">
            <div className="box-1">
              <div className="SNo">1</div>
              <div className="box_txt">
                Go to our <b>Exchange</b>
              </div>
            </div>
            <img src={right} className="l"></img>
            <div className="box-1">
              <div className="SNo">2</div>
              <div className="box_txt">
                Buy Tron/BNB/ETH for your <b>TronLink/Metamask</b> or web wallet
              </div>
            </div>{" "}
            <img src={right} className="l"></img>
            <div className="box-1">
              <div className="SNo">3</div>
              <div className="box_txt">
                Enter TRX/ETH/BNB value of the amount of CETO you want
              </div>
            </div>{" "}
          </div>{" "}
          <img src={down} className="l down_icon"></img>
          <div className="box-0">
            <div className="box-1">
              <div className="SNo">6</div>
              <div className="box_txt">
                Enjoy worry-free <b>passive income!</b>
              </div>
            </div>{" "}
            <img src={left} className="l"></img>
            <div className="box-1">
              <div className="SNo">5</div>
              <div className="box_txt">
                Set your auto-reinvestment option to automatically{" "}
                <b>compound your returns</b>
              </div>
            </div>{" "}
            <img src={left} className="l"></img>
            <div className="box-1">
              <div className="SNo">4</div>
              <div className="box_txt">
                Click <b>Buy CETO.</b> Make sure you have some of your chosen
                coin for gas fees before you do! (we recommend keeping 250+
                TRX/0.08 ETH/0.1 BNB(BEP20) in your wallet)
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="hourglass s">
        <span>What's next?</span>
        <div className="box-0">
          <div className="box-1">
            <div className="SNo">1</div>
            <div className="box_txt">
              Go to our <b>Exchange</b>
            </div>
          </div>
          <div className="box-1">
            <div className="SNo">2</div>
            <div className="box_txt">
              Buy Tron/Eth for your <b>TronLink/Metamask</b> or web wallet
            </div>
          </div>{" "}
          <div className="box-1">
            <div className="SNo">3</div>
            <div className="box_txt">
              Enter TRX/ETH/BNB value of the amount of CETO you want
            </div>
          </div>{" "}
          <div className="box-1">
            <div className="SNo">4</div>
            <div className="box_txt">
              Click <b>Buy CETO.</b> Make sure you have some of your chosen coin
              for gas fees before you do! (we recommend keeping 250+ TRX/0.08
              ETH/0.1 BNB(BEP20) in your wallet)
            </div>
          </div>{" "}
          <div className="box-1">
            <div className="SNo">5</div>
            <div className="box_txt">
              Set your auto-reinvestment option to automatically{" "}
              <b>compound your returns</b>
            </div>
          </div>{" "}
          <div className="box-1">
            <div className="SNo">6</div>
            <div className="box_txt">
              Enjoy worry-free <b>passive income!</b>
            </div>
          </div>
        </div>{" "}
      </div>

      {/* Features */}
      <div className="wisdom">
        <span>Features</span>

        <div className="wisdom__content">
          <img alt="landing_imgs" src={WisdomIllustration} />

          <div className="wisdom__contentSliderPart">
            <div className="wisdom__sliderContainer">
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Smart</span>
                <span>
                  Autonomous, trustless, decentralized - the way contracts
                  oughta be.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Fair</span>
                <span>
                  All transactions taxed 10% - and distributed to those savvy
                  tokenholders.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Patient</span>
                <span>
                  If you can’t handle CETO on its tenth day, you don’t deserve
                  it on its thirtieth.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Abundant</span>
                <span>
                  Always there when you need it: no limit on tokens! But the
                  more TRX/ETH/BNB in the contract, the more expensive the CETO.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Fun</span>
                <span>
                  Everyday is payday. Making money’s still fun around here,
                  right?
                </span>
              </div>
            </div>

            <div className="wisdom__sliderContainer">
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Simple</span>
                <span>
                  Buy or sell tokens, the price goes up/down a little bit more
                  (~.0001 TRX/0.00000001 ETH/0.0000005 BNB). Easy peasy.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Just</span>
                <span>
                  We buy when you buy - and we’re in this for the long haul.
                  Everyday is someone’s thirtieth day.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Friendly</span>
                <span>
                  Share the wealth and invite some friends! Get your own
                  referral link and enjoy 1/3 of your referrals’ dividends as
                  well.
                </span>
              </div>
              <div className="wisdom__slide">
                <span className="wisdom__slideHeading">Vetted</span>
                <span>
                  Based on the popular P3D open source contract, incentivized
                  for longevity. We didn’t invent the hourglass contract - we
                  just made it better.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Feed Us Peanuts */}
      <div className="feedUsPeanuts">
        <span>Feed Us Peanuts</span>

        <div>
          <a target="_blank" href="https://twitter.com/cetoken">
            <img alt="landing_imgs" src={TwitterIcon} />
            Twitter
          </a>

          <a target="_blank" href="https://discord.com/invite/Jxg8GzZ3kq">
            <img alt="landing_imgs" src={DiscordIcon} />
            Discord
          </a>

          <a target="_blank" href="https://t.me/cetoken">
            <img alt="landing_imgs" src={TelegramIcon} />
            Telegram
          </a>
        </div>
      </div>

      {/* Q&A */}
      <div className="questionsContainer">
        <span>Rules of the Jungle</span>

        <div className="questionsBlock">
          {questionsArray.map((question, idx) => (
            <React.Fragment key={`QA_${idx}`}>
              <div className="question">
                {question.question}
                {question.isOpen ? (
                  <IconButton onClick={e => toggleQA(e, idx)}>
                    <RemoveCircleIcon style={{ color: " #009EE4" }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={e => toggleQA(e, idx)}>
                    <AddCircleIcon style={{ color: "#691AAA" }} />
                  </IconButton>
                )}
              </div>
              {question.isOpen && (
                <div className="answer">{ReactHtmlParser(question.answer)}</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Copyright */}
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={status == 1}
        onClose={() => setStatus(0)}
        autoHideDuration={6000}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setStatus(0)} severity="success">
          Subscribed!
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => {
  console.log(state, "state");
  return {
    currencies: state.utils.currencies,
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Landing);
