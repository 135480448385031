import React, { useState, useEffect } from "react";
import CinemaDraftButton from "../cinema-draft-button/cinema-draft-button.component";
import eth from "../../public/ethe.png";
import bnb from "../../public/bnb.png";
import ArrowInButton from "../../public/blue_arrow.png";
import "../../styles/banner.css";
const Banner = props => {
  const [timer, setTimer] = useState();
  const [live, setLive] = useState(false);
  useEffect(async () => {
    const offset = 48600;
    const end_timestamp = 1617678000;

    var x = setInterval(function () {
      // Get today's date and time
      const currentDate = new Date();
      const now = parseInt(currentDate.getTime().toString().slice(0, -3));

      // Find the distance between now and the count down date
      var distance = end_timestamp - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (60 * 60 * 24));
      var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
      var minutes = Math.floor((distance % (60 * 60)) / 60);
      var seconds = Math.floor(distance % 60);

      // Display the result in the element with id="demo"
      setTimer({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setTimer();
        setLive(true);
      }
    }, 1000);
  }, []);
  return (
    <div className="banner">
      <span className="txt">
        Check out our Dottie and Friends NFTs on{" "}
        <a target="_blank" href="https://opensea.io/accounts/Dottie">
          OpenSea
        </a>
      </span>
      {!live && (
        <span className="banner-txt-2">
          {timer
            ? timer.days < 10
              ? String(timer.days).padStart(2, "0")
              : timer.days
            : "00"}{" "}
          d{" "}
          {timer
            ? timer.hours < 10
              ? String(timer.hours).padStart(2, "0")
              : timer.hours
            : "00"}{" "}
          h{" "}
          {timer
            ? timer.minutes < 10
              ? String(timer.minutes).padStart(2, "0")
              : timer.minutes
            : "00"}{" "}
          m{" "}
          {timer
            ? timer.seconds < 10
              ? String(timer.seconds).padStart(2, "0")
              : timer.seconds
            : "00"}{" "}
          s
        </span>
      )}{" "}
    </div>
  );
};

export default Banner;
