import React, { useEffect, useState, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";

import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import axios from "axios";
import * as actions from "../../actions";
import { Input, Tooltip, Progress, InfoCircleOutlined, Button } from "antd";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { BuysellStyle } from "./style";
import exchange from "../../public/Exchange - Icon.svg";
import arrow from "../../public/Button - Icon.svg";
import arrow2 from "../../public/Button - Icon (2).svg";
import Buypopup from "../buypopup/wrappedBuypopup";
import Transferpopup from "../transferpopup/transferpopup";
import { connect } from "react-redux";
import Contract_obj from "../../getWrappedContract";
import Sellpopup from "../sellpopup/wrappedSellpopup.js";
import AlertDialogSlide from "../infoBox";
import input_icon from "../../public/input_icon.png";
import convert_icon from "../../public/convert.png";
import wrappedConfig from "../../wrapped-config.js";
import ConnectionModal from "../connectionpopup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ceto_contract_obj from "../../getTronweb";
import web3_obj from "../../getWrappeddContractWeb3";
import ceto_web3_obj from "../../getWeb3";
import TronTxDecoder from "tron-tx-decoder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const WrappedBuysell = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  timer,
  txnLoading,
  setTxnLoading,
  ...props
}) => {
  const [trx, setTrx] = useState();
  const [trxToTokens, setTrxToTokens] = useState();
  const [tokens, setTokens] = useState();
  const [open, setmodal] = useState(false);
  const [openSell, setSell] = useState(false);
  const [trons, setTrons] = useState();
  const [processing, setProcessing] = useState(false);
  const mounted = useRef();
  const [connectModal, setConnectModal] = useState(false);
  const [connection_issue, setConnectionIssue] = useState(0);
  const [err, handleCloseErr] = useState(false);
  const [err_msg, set_err_msg] = useState("Error");
  const [accBalance, setAccBalance] = useState();
  const [step, setStep] = useState(1);
  const [ceto, setCeto] = useState();
  const [wceto, setWceto] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [totalWceto, setTotalWceto] = useState();
  const [totalCeto, setTotalCeto] = useState();
  const [status, setStatus] = useState(0);

  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const [allowance, setAllowance] = useState();
  const [positions, setPositions] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positions;
  const handleClose = () => {
    setmodal(false);
  };

  const fetchData = async () => {
    setProcessing(false);
    setCeto();
    setWceto();
    // window.tronWeb &&
    //   (await Contract_obj.init(
    //     window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
    //       ? "shasta"
    //       : window.tronWeb.fullNode.host == "https://api.trongrid.io"
    //       ? "mainnet"
    //       : metacoinConfig["private"].fullHost
    //   ));
    console.log(Contract_obj, "Contract_obj_wrapped");

    (await Contract_obj.contract_instace) &&
      Contract_obj.contract_instace
        .myTokens()
        .call({
          from: Contract_obj.accounts[0],
          feeLimit: Contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            console.log(res.toString(), "myTokens");

            setTokens(res.toString());
          }
        });
    (await ceto_contract_obj.contract_instace) &&
      ceto_contract_obj.contract_instace
        .myTokens()
        .call({
          from: ceto_contract_obj.accounts[0],
          feeLimit: ceto_contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            setTotalCeto(parseInt(res.toString()));
          }
        });
    var node__ =
      window.tronWeb &&
      window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
        ? "shasta"
        : "mainnet";
    (await ceto_contract_obj.contract_instace) &&
      ceto_contract_obj.contract_instace
        .allowance(
          ceto_contract_obj.accounts[0],
          wrappedConfig[node__].contractAddress
        )
        .call({
          from: ceto_contract_obj.accounts[0],
          feeLimit: ceto_contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            console.log(res.toString(), "allowance");
            setAllowance(parseInt(res.toString()) / 1e6);
          }
        });
    (await ceto_contract_obj.contract_instace) &&
      Contract_obj.contract_instace
        .balanceOf(Contract_obj.accounts[0])
        .call({
          from: ceto_contract_obj.accounts[0],
          feeLimit: ceto_contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            console.log(res.toString(), "balanceOf");
            setTotalWceto(parseInt(res.toString()));
          }
        });
  };
  const ethData = async () => {
    setProcessing(false);
    setCeto();
    setWceto();
    console.log("kdl");

    if (web3_obj.contract_instace && web3_obj.accounts[0][0]) {
      console.log("kdl");
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(my_tokens, "weth-token");
      setTokens(parseInt(my_tokens));

      var my_tokens = await ceto_web3_obj.contract_instace.methods
        .myTokens()
        .call({
          from: ceto_web3_obj.accounts[0][0],
          gas: ceto_web3_obj.feeLimit,
        });
      console.log(my_tokens, "eth-all-token");
      setTotalCeto(parseInt(my_tokens).toFixed(3));

      var res = await web3_obj.contract_instace.methods
        .balanceOf(web3_obj.accounts[0][0])
        .call({
          from: web3_obj.accounts[0][0],
          gas: web3_obj.feeLimit,
        });
      console.log(res, "weth-all-token");
      setTotalWceto(parseInt(res));

      var res = await ceto_web3_obj.contract_instace.methods
        .allowance(web3_obj.accounts[0][0], web3_obj.contract_instace._address)
        .call({
          from: ceto_web3_obj.accounts[0][0],
          gas: ceto_web3_obj.feeLimit,
        });
      console.log(res, "allowance", web3_obj.contract_instace._address);
      setAllowance(parseInt(res) / 1e18);

      var res = await ceto_web3_obj.contract_instace.methods
        .allowance(
          ceto_web3_obj.accounts[0][0],
          web3_obj.contract_instace._address
        )
        .call({
          from: ceto_web3_obj.accounts[0][0],
          gas: ceto_web3_obj.feeLimit,
        });
      console.log(res, "allowance");
    }
  };
  useEffect(() => {
    if (props.utils.blockchain == "trx") {
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          // console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            (window.tronWeb.fullNode.host ==
              wrappedConfig["mainnet"].fullHost ||
              window.tronWeb.fullNode.host == wrappedConfig["shasta"].fullHost)
          ) {
            fetchData();
          }
        }
      });
    }
    props.utils.blockchain == "eth" || props.utils.blockchain == "bnb"
      ? ethData()
      : fetchData();
  }, []);

  useEffect(() => {
    (Contract_obj.contract_instace && props.utils.blockchain == "eth") ||
    props.utils.blockchain == "bnb"
      ? ethData()
      : fetchData();
  }, [updated, theme, props.utils.blockchain]);

  useEffect(() => {
    console.log(props.utils.blockchain, "blck");
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth" || props.utils.blockchain == "bnb"
      ? ethData()
      : fetchData();
  }, [props.utils.blockchain]);
  const checkConnection = async v => {
    // console.log(Contract_obj, "Contract_obj_");
    if (props.utils.blockchain == "eth") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![1, 3].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        if (v == 1 && ceto) {
          setmodal(true);
          console.log("chekc");
          setStep(1);
        } else if (v == 2 && ceto) {
          setSell(true);
        }
      }
    } else if (props.utils.blockchain == "bnb") {
      if (
        !web3_obj.contract_instace ||
        !web3_obj.accounts[0][0] ||
        ![97, 56].includes(web3_obj.networkId)
      ) {
        setConnectModal(true);
      } else {
        if (v == 1 && ceto) {
          setmodal(true);
          console.log("chekc");
          setStep(1);
        } else if (v == 2 && ceto) {
          setSell(true);
        }
      }
    } else {
      if (
        !window.tronWeb ||
        (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
      ) {
        setConnectModal(true);
      } else if (
        window.tronWeb &&
        window.tronWeb.fullNode.host != wrappedConfig["mainnet"].fullHost &&
        window.tronWeb.fullNode.host != wrappedConfig["shasta"].fullHost
      ) {
        setConnectionIssue(1);
        setConnectModal(true);
      } else {
        parseInt(ceto) > allowance ? setStep(1) : setStep(2);
        window.tronWeb &&
          (await window.tronWeb.trx
            .getBalance(Contract_obj.accounts[0])
            .then(result => {
              console.log(result, "balance", v, trx);
              setAccBalance(result);
            }));
        if (v == 1 && ceto) {
          setmodal(true);
        } else if (v == 2 && ceto) {
          setSell(true);
        }
      }
    }
  };

  const setDisabled = v => {
    if (
      !window.tronWeb ||
      (window.tronWeb && window.tronWeb.defaultAddress.hex == false)
    ) {
      return false;
    } else if (
      window.tronWeb &&
      window.tronWeb.fullNode.host != wrappedConfig.fullHost
    ) {
      return false;
    } else if (processing) {
      return true;
    } else {
      if (v == 1 && trx) {
        return false;
      } else if (v == 2 && trxToTokens) {
        return false;
      } else {
        return true;
      }
    }
  };
  const buy = async () => {
    if (props.utils.blockchain == "trx") {
      // try {
      var res = await Contract_obj.buyFunc(ceto * factor);
      // console.log("tron buy", Contract_obj, res);
      setProcessing(true);

      setTimeout(async () => {
        setUpdated(updated + 1);
        setCeto();
        setWceto();
        setStep(1);
      }, 5000);
    } else {
      try {
        setTxnLoading(true);

        await web3_obj.buyFunc(
          (ceto * factor).toLocaleString("fullwide", {
            useGrouping: false,
          })
        );
        setTxnLoading(false);
        setStatus(1);
        setTimeout(async () => {
          setUpdated(updated + 1);
          setCeto();
          setWceto();
          setStep(1);
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    }
  };

  const approve = async () => {
    setStep(1);
    console.log("approving");
    if (props.utils.blockchain == "trx") {
      try {
        var res = await ceto_contract_obj.approveFunc(
          ceto * factor,
          Contract_obj.contractAddress
        );

        setProcessing(true);
        setTimeout(async () => {
          setProcessing(false);

          setStep(2);
        }, 5000);
      } catch (error) {
        console.log("err approve", err);
        setProcessing(false);
        set_err_msg("You don't have sufficient balance in your account!");
        handleCloseErr(true);
      }
    } else {
      try {
        console.log(web3_obj.contract_instace._address);
        setTxnLoading(true);

        await ceto_web3_obj.approveFunc(
          (ceto * factor).toLocaleString("fullwide", {
            useGrouping: false,
          }),
          web3_obj.contract_instace._address
        );
        setStatus(1);

        setTxnLoading(false);

        setTimeout(async () => {
          setTxnLoading(false);

          setStep(2);
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    }
  };
  const sell = async () => {
    if (props.utils.blockchain == "trx") {
      try {
        var res = await Contract_obj.sellFunc(
          (wceto * factor).toLocaleString("fullwide", {
            useGrouping: false,
          })
        );

        setSell(false);
        setProcessing(true);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCeto();
          setWceto();
        }, 5000);
      } catch (error) {
        console.error(error);
        setProcessing(false);
        set_err_msg("You don't have enough CETO!");
        handleCloseErr(true);
      }
    } else {
      try {
        console.log(web3_obj.contract_instace._address, wceto);
        setTxnLoading(true);
        setSell(false);

        await web3_obj.sellFunc(wceto);
        setStatus(1);

        setTxnLoading(false);

        setTimeout(async () => {
          setUpdated(updated + 1);
          setCeto();
          setWceto();
        }, 1000);
      } catch (error) {
        console.log(error);
        if (error.message.includes("User denied transaction signature")) {
          console.error("error caught right");
        } else {
          setStatus(2);
        }
        setTxnLoading(false);
      }
    }
  };

  const convert = async value => {
    console.log(totalCeto, "totalCeto");
    setCeto(value);
    value = (value * factor).toLocaleString("fullwide", { useGrouping: false });
    console.log(value);
    if (parseInt(value) > totalCeto) {
      set_err_msg("You don't have enough CETO!");
      handleCloseErr(true);
      setWceto();
    } else {
      if (props.utils.blockchain == "trx") {
        try {
          Contract_obj.contract_instace &&
            (await Contract_obj.contract_instace
              .wrappedTokensMinted_(value)
              .call({
                from: Contract_obj.accounts[0],
                feeLimit: Contract_obj.feeLimit,
                callValue: Contract_obj.callValue || 0,
              })
              .then(function (res) {
                if (res) {
                  console.log(res.toString(), "CETO TOKENS");
                  setWceto(res.toString());
                  set_err_msg("");
                  handleCloseErr(false);
                }
              }));
        } catch (error) {
          console.log("err");
        }
      } else {
        if (
          (props.utils.blockchain == "eth" &&
            (web3_obj.networkId == 3 || web3_obj.networkId == 1)) ||
          (props.utils.blockchain == "bnb" &&
            (web3_obj.networkId == 97 || web3_obj.networkId == 56))
        )
          var my_tokens = await web3_obj.contract_instace.methods
            .wrappedTokensMinted_(value)
            .call({
              from: web3_obj.accounts[0][0],
              gas: Contract_obj.feeLimit,
            });
        console.log(my_tokens, "wtoken");
        setWceto(parseInt(my_tokens));
        set_err_msg("");
        handleCloseErr(false);
      }
    }
  };

  const convert_ceto = async value => {
    console.log(wceto);
    setWceto(value);
    value = value * factor;

    if (value > totalWceto) {
      set_err_msg("You don't have enough WCETO!");
      handleCloseErr(true);
      setCeto();
    } else {
      if (props.utils.blockchain == "trx") {
        Contract_obj.contract_instace &&
          (await Contract_obj.contract_instace
            .cetoTokensTransfered_(value)
            .call({
              from: Contract_obj.accounts[0],
              feeLimit: Contract_obj.feeLimit,
              callValue: Contract_obj.callValue || 0,
            })
            .then(function (res) {
              if (res) {
                console.log(res.toString(), "CETO TOKENS");
                setCeto(res.toString());
                set_err_msg("");
                handleCloseErr(false);
              }
            }));
      } else {
        if (
          (props.utils.blockchain == "eth" &&
            (web3_obj.networkId == 3 || web3_obj.networkId == 1)) ||
          (props.utils.blockchain == "bnb" &&
            (web3_obj.networkId == 97 || web3_obj.networkId == 56))
        ) {
          try {
            var res = await web3_obj.contract_instace.methods
              .cetoTokensTransfered_(
                value.toLocaleString("fullwide", { useGrouping: false })
              )

              .call({
                from: web3_obj.accounts[0][0],
                gas: Contract_obj.feeLimit,
              });
            console.log(res, "wtoken");
            setCeto(parseInt(res));
            set_err_msg("");
            handleCloseErr(false);
          } catch (error) {
            //pass
          }
        }
      }
    }
  };

  return (
    <div>
      <BuysellStyle
        theme={theme}
        width={props.width && props.width}
        style={{ marginBottom: "0" }}
      >
        <div className={`buysell_container ${theme > 1 ? "newtheme" : ""}`}>
          <div className="upper">
            <span className="light">
              {theme <= 1 ? "Buy" : theme == 2 ? "Sell" : "Transfer"} WCETO
            </span>
            <span className="lmobile">
              {theme <= 1 ? "Buy" : theme == 2 ? "Sell" : "Transfer"} WCETO
            </span>
            {/* <span className="medium">
              {trxPerToken && (trxPerToken / factor).toFixed(3)} TRX/CETO
            </span> */}
          </div>
          <div className="flex1">
            <div className="txt11">
              Enter {theme <= 1 ? "CETO" : "WCETO"} Value
            </div>
            <div className="txt11 right ss">
              {theme <= 1
                ? "WCETO You Get"
                : theme == 2
                ? "CETO You Get"
                : "They Get"}
            </div>
          </div>
          <div className="convert">
            <Input
              className="inputText"
              prefix={
                <img
                  src={
                    theme <= 1
                      ? "https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg"
                      : input_icon
                  }
                />
              }
              type="number"
              value={theme <= 1 ? ceto : wceto}
              onChange={e =>
                // Contract_obj.contract_instace &&
                theme <= 1
                  ? convert(e.target.value)
                  : convert_ceto(e.target.value)
              }
            />

            <button className={`convertbutton`}>
              <img src={convert_icon} />
            </button>

            <Input
              className="resultText"
              suffix={
                <img
                  src={
                    theme == 2
                      ? "https://res.cloudinary.com/dgdjabqwr/image/upload/v1610200564/cd3d/Group_5257_xyltnd.svg"
                      : input_icon
                  }
                />
              }
              type="number"
              value={
                theme <= 1
                  ? wceto && (wceto / factor).toFixed(6)
                  : ceto && (ceto / factor).toFixed(6)
              }
            />
          </div>

          <p className={`medium  rt`}>{/*  */}</p>
          <div class="wrapper">
            <div class="bg">
              <div class="el"></div>
            </div>
          </div>

          {theme <= 1 ? (
            <div
              id="button-wrappered"
              className={`${processing && step == 2 ? "checked" : ""}`}
              onClick={() => !processing && checkConnection(1)}
              style={{
                cursor: setDisabled(1) && "not-allowed",
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                {" "}
                <defs>
                  {" "}
                  <filter id="goo">
                    {" "}
                    <feGaussianBlur
                      in="SourceGraphic"
                      stdDeviation="10"
                      result="blur"
                    />{" "}
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                      result="goo"
                    />{" "}
                    <feBlend in="SourceGraphic" in2="goo" />{" "}
                  </filter>{" "}
                </defs>
              </svg>
              <p
                className={`${
                  processing && step == 2 ? "submited circle" : "submited"
                }`}
              >
                Buy WCeto{" "}
                <img
                  className={`${processing && step == 2 ? "hideArrow" : ""}`}
                  src={arrow}
                />
              </p>
              <div className="loader-wrapper">
                <span className="loader yellow"></span>
                <span className="loader pink"></span>
              </div>
              <span className="loader orange"></span>

              <div className="check-wrapper">
                <svg
                  version="1.1"
                  width="65px"
                  height="38px"
                  viewBox="0 0 64.5 37.4"
                >
                  <polyline className="check" points="5,13 21.8,32.4 59.5,5 " />
                </svg>
              </div>
            </div>
          ) : // <AnimatedButton
          //   loading={processing}
          //   onClick={e => {
          //     !processing && checkConnection(1);
          //   }}
          // />
          // <GreenButton
          //   style={{
          //     background: "#ffffff",
          //     color: "#002E8C",
          //     marginTop: "37px",
          //     cursor: setDisabled(1) && "not-allowed"
          //   }}
          //   onClick={() => !processing && checkConnection(1)}
          // >
          //   Buy CETO
          //   {processing ? (
          //     <CircularProgress
          //       width={28}
          //       height={28}
          //       style={{ width: "28px", height: "28px", marginLeft: "1rem" }}
          //     />
          //   ) : (
          //     <img src={arrow} />
          //   )}
          // </GreenButton>
          theme == 2 ? (
            <div
              id="button-wrappered"
              className={`${processing ? "checked" : ""}`}
              onClick={() => !processing && checkConnection(2)}
              style={{
                cursor: setDisabled(1) && "not-allowed",
                color: "white",
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                {" "}
                <defs>
                  {" "}
                  <filter id="goo">
                    {" "}
                    <feGaussianBlur
                      in="SourceGraphic"
                      stdDeviation="10"
                      result="blur"
                    />{" "}
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                      result="goo"
                    />{" "}
                    <feBlend in="SourceGraphic" in2="goo" />{" "}
                  </filter>{" "}
                </defs>
              </svg>
              <p
                style={{
                  background: "#009EE4",
                }}
                className={`${processing ? "submited circle" : "submited"}`}
              >
                Sell WCeto{" "}
                <img
                  className={`${processing ? "hideArrow" : ""}`}
                  src={arrow2}
                />
              </p>
              <div className="loader-wrapper">
                <span className="loader blue"></span>
                <span className="loader darkblue"></span>
              </div>
              <span className="loader white"></span>

              <div className="check-wrapper">
                <svg
                  version="1.1"
                  width="65px"
                  height="38px"
                  viewBox="0 0 64.5 37.4"
                >
                  <polyline className="check" points="5,13 21.8,32.4 59.5,5 " />
                </svg>
              </div>
            </div>
          ) : null}
        </div>
      </BuysellStyle>
      <Buypopup
        open={open}
        handleClose={handleClose}
        ceto={ceto}
        wceto={wceto}
        buy={buy}
        approve={approve}
        step={step}
        processing={processing}
        accBalance={accBalance}
        blockchain={props.utils.blockchain}
        allowance={allowance}
      />
      <Sellpopup
        open={openSell}
        handleClose={setSell}
        ceto={ceto}
        wceto={wceto}
        sell={sell}
        accBalance={accBalance}
        blockchain={props.utils.blockchain}
      />
      <AlertDialogSlide
        theme={theme}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
      ></AlertDialogSlide>
      <ConnectionModal
        openModal={connectModal}
        connection_issue={connection_issue}
        setConnectModal={setConnectModal}
        blockchain={props.utils.blockchain}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={err}
        autoHideDuration={6000}
        onClose={() => handleCloseErr(false)}
      >
        <Alert onClose={() => handleCloseErr(false)} severity="error">
          {err_msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={status == 1}
        onClose={() => setStatus(0)}
        autoHideDuration={6000}
        key={vertical}
        className="notifs"
      >
        <div>
          <CheckCircleIcon
            style={{ color: "#10c983", marginRight: "20px" }}
          ></CheckCircleIcon>
          <span>Transaction completed!</span>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={status == 2}
        onClose={() => setStatus(0)}
        autoHideDuration={600000}
        key={vertical + horizontal}
        className="notifs"
      >
        <div style={{ padding: "0px 16px" }}>
          <CancelIcon
            style={{ color: "#be0606", marginRight: "10px" }}
          ></CancelIcon>
          <span>Transaction failed!</span>
          <IconButton onClick={() => setStatus(0)}>
            <ClearIcon style={{ marginleft: "2 em" }}></ClearIcon>
          </IconButton>
        </div>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(WrappedBuysell);
