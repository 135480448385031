export const LOGOUT_USER = "LOGOUT_USER";
export const USER_REGISTERED = "USER_REGISTERED";
export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const RESET_ERRORS = "USER_LOGGEDIN";
export const PROFILE_SET = "PROFILE_SET";
export const PROFILE_RESET = "PROFILE_RESET";
export const CURRENCIES = "CURRENCIES";
export const SET_ENV = "SET_ENV";
export const BLOCKCHAIN = "BLOCKCHAIN";

export const HIDE_FULL_LOADER = "HIDE_FULL_LOADER";
