import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import Dropdownn from "../dropdown/dropdown_";
import styles from "./actionbar.module.scss";
import axios from "axios";
import trximage from "../../public/TRX per CD3D - Icon.svg";
import contract_img from "../../public/TRX in contract - Icon.svg";
import Contract_obj from "../../getTronweb";
import { connect } from "react-redux";
import * as actions from "../../actions";
import metacoinConfig from "../../metacoin-config.js";
import { Tooltip } from "antd";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import AnimatedButton from "../AnimatedButton";
import web3_obj from "../../getWeb3";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import eth_purple from "../../public/eth_purple.png";
import bnb_yellow from "../../public/bnb_yellow.png";
import trximage2 from "../../public/TRX per CD3D - yellow.png";
import contract_img2 from "../../public/TRX in contract - yellow.png";
import ceto_yellow from "../../public/ceto_yellow.png";
import axiosInstance from "../../config/axios";

const Actionbar = ({ updated, history, flag, page, theme, ...props }) => {
  const [tokens, setTokens] = useState();
  const [trxPerToken, setTrxPerToken] = useState();
  const [usd, setUsd] = useState();
  const [tron, setTron] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const Formatter = num => {
    return Math.abs(num) > 999999
      ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
      : Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num);
  };

  const fetchData = async force => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      console.log(
        "testtv",
        Contract_obj.accounts[0],
        Contract_obj.tronWebProvider.defaultAddress.base58
      );
      axiosInstance
        .get(
          `/api/cache/?function=myTokens&expiry=30&force=${force}&network=${
            window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
              ? "shasta"
              : window.tronWeb.fullNode.host == "https://api.trongrid.io"
              ? "mainnet"
              : metacoinConfig["private"].fullHost
          }&contract_address=${
            metacoinConfig["private"].contractAddressBase
          }&sender_address=${
            Contract_obj.tronWebProvider.defaultAddress.base58
          }`,
          {
            timeout: 50000,
          }
        )
        .then(resp => {
          console.log(resp.data.data, "setTokens ressp");
          var data = resp.data.data;
          setTokens(parseFloat(data) / 1e6);

          if (parseFloat(data) == 0) {
            setTron(0);
          } else {
            axiosInstance
              .get(
                `/api/cache/?function=tokensToTron_&expiry=30&force=${force}&arguments=${parseInt(
                  data
                )}&network=${
                  window.tronWeb.fullNode.host ==
                  "https://api.shasta.trongrid.io"
                    ? "shasta"
                    : window.tronWeb.fullNode.host == "https://api.trongrid.io"
                    ? "mainnet"
                    : metacoinConfig["private"].fullHost
                }&contract_address=${
                  metacoinConfig["private"].contractAddressBase
                }&sender_address=${
                  Contract_obj.tronWebProvider.defaultAddress.base58
                }`,
                {
                  timeout: 50000,
                }
              )
              .then(resp => {
                console.log(resp.data.data, "setTron");
                setTron(parseInt(resp.data.data) / 1e6);
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
      axiosInstance
        .get(
          `/api/cache/?function=buyPrice&expiry=30&force=${force}&network=${
            window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
              ? "shasta"
              : window.tronWeb.fullNode.host == "https://api.trongrid.io"
              ? "mainnet"
              : metacoinConfig["private"].fullHost
          }&contract_address=${
            metacoinConfig["private"].contractAddressBase
          }&sender_address=${
            Contract_obj.tronWebProvider.defaultAddress.base58
          }`,
          {
            timeout: 50000,
          }
        )
        .then(resp => {
          console.log(resp.data.data, "resspo");
          var res = resp.data.data;
          setTrxPerToken((parseFloat(res) / 1e6).toFixed(3));
        })
        .catch(err => {
          console.log(err);
        });
      axiosInstance
        .get(`/api/landing/page/data`, {
          timeout: 50000,
        })
        .then(resp => {
          console.log(resp.data.data, "resp");
          var data = resp.data.data;
          setContractBalance(data.trx_in_contract);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const ethData = async () => {
    if (
      web3_obj.web3_instance &&
      web3_obj.contract_instace &&
      web3_obj.accounts[0][0] &&
      (web3_obj.networkId == 3 || web3_obj.networkId == 1)
    ) {
      var c_balance = await web3_obj.web3_instance.eth.getBalance(
        web3_obj.contract_instace.options.address
      );
      setContractBalance(parseFloat(c_balance) / 1e18);
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseFloat(my_tokens) / 1e18);
      if (my_tokens == 0) {
        setTron(0);
      } else {
        var trons_ = await web3_obj.contract_instace.methods
          .calculateEthereumReceived(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
        setTron(parseFloat(trons_) / 1e18);
      }

      var price = await web3_obj.contract_instace.methods
        .buyPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setTrxPerToken(parseFloat(price) / 1e18);

      console.log(price, "eth price");
    }
  };
  const bnbData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var c_balance = await web3_obj.web3_instance.eth.getBalance(
        web3_obj.contract_instace.options.address
      );
      setContractBalance(parseFloat(c_balance) / factor);
      // console.log("contract balance", c_balance);
      var price = await web3_obj.contract_instace.methods
        .buyPrice()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setTrxPerToken(parseFloat(price) / factor);
      // console.log("buyPrice", price);

      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseFloat(my_tokens) / factor);
      console.log("setTokens", my_tokens);

      if (my_tokens == 0) {
        setTron(0);
        console.log("setBalancee", 0);
      } else {
        var res = await web3_obj.contract_instace.methods
          .calculateEthereumReceived(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        setTron(parseFloat(res) / factor);
        console.log("setBalancee", res);
      }

      // console.log("setCetoIfReinvested", res);
    }
  };
  // useEffect(() => {
  //   if (tokens && props.utils.blockchain != "eth") {
  //     Contract_obj.contract_instace
  //       .tokensToTron_(parseFloat(tokens) * 1e6)
  //       .call({
  //         from: Contract_obj.accounts[0],
  //         feeLimit: Contract_obj.feeLimit,
  //       })
  //       .then(function (tron) {
  //         setTron(parseFloat(tron.toString()) / 1e6);
  //       });
  //   } else if (tokens == 0) {
  //     setTron(0);
  //   }
  // }, [tokens]);
  useEffect(() => {
    if (props.utils.blockchain == "eth") {
      ethData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      var obj = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          clearInterval(obj);
          window.tronWeb && fetchData(1);
        }
      }, 2);
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          // console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            window.tronWeb && fetchData(1);
          }
        }
      });
      window.addEventListener("load", async event => {
        console.log("loaded");
        var obj1 = setInterval(async () => {
          if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
            console.log("loadeded");

            clearInterval(obj1);
            window.tronWeb && fetchData(1);
          }
        }, 2000);
      });
    }
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth" || props.utils.blockchain == "bnb"
      ? ethData()
      : window.tronWeb && fetchData(0);
    setTimeout(() => {
      console.log("vdbv");
      window.tronWeb && fetchData(0);
    }, 3000);
    window.addEventListener("load", async event => {
      console.log("loaded");
      var obj1 = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          console.log("loadeded");

          clearInterval(obj1);
          window.tronWeb && fetchData(1);
        }
      }, 2000);
    });
    console.log("cdnl");
  }, []);
  useEffect(() => {
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth"
      ? ethData()
      : props.utils.blockchain == "bnb"
      ? bnbData()
      : window.tronWeb && fetchData(1);
  }, [updated, theme, props.utils.blockchain, props.location.pathname]);

  const getColor = type => {
    if (type == 0) {
      if (props.blockchain == "trx") {
        return "#009EE4";
      } else if (props.blockchain == "bnb") {
        return "#BF9C00";
      } else {
        return "#691AAA";
      }
    } else if (type == 1) {
      if (props.blockchain == "trx") {
        return "#002E8C";
      } else if (props.blockchain == "bnb") {
        return "#000000";
      } else {
        return "#000000";
      }
    } else if (type == 2) {
      if (props.blockchain == "trx") {
        return "#009EE4";
      } else if (props.blockchain == "bnb") {
        return "#BF9C00";
      } else {
        return "#691AAA";
      }
    } else if (type == 3) {
      if (props.blockchain == "trx") {
        return "#002E8C";
      } else if (props.blockchain == "bnb") {
        return "#691AAA";
      } else {
        return "#000000";
      }
    } else if (type == 4) {
      if (props.blockchain == "trx") {
        return "#691AAA";
      } else if (props.blockchain == "bnb") {
        return "#EDC201";
      } else {
        return "#691AAA";
      }
    } else if (type == 5) {
      if (props.blockchain == "trx") {
        return "white";
      } else if (props.blockchain == "bnb") {
        return "black";
      } else {
        return "white";
      }
    }
  };

  return (
    <>
      <div
        className={styles.actionbar_div}
        style={{
          display: flag == 0 && "none",
          backgroundColor: props.blockchain == "eth" ? "#E8E8E8" : "#F1F3FC",
        }}
      >
        <span className={styles.action_item} style={{ color: getColor(0) }}>
          My Holdings
        </span>
        <div className={styles.action_stat}>
          <img
            src={
              props.blockchain == "bnb"
                ? ceto_yellow
                : "https://res.cloudinary.com/dgdjabqwr/image/upload/v1609932216/cd3d/Group_4092_1_kzlrrz.png"
            }
            width="24"
            height="24"
          />
          <span className={styles.stat}>
            {tokens && tokens.toFixed(3)}{" "}
            {props.blockchain == "eth"
              ? "ECETO"
              : props.blockchain == "bnb"
              ? "BCETO"
              : "CETO"}
          </span>
        </div>
        {/* <CompareArrowsIcon></CompareArrowsIcon> */}
        <span>~</span>
        <div className={styles.action_stat}>
          <img
            src={
              props.blockchain == "trx"
                ? "https://res.cloudinary.com/dgdjabqwr/image/upload/v1610713939/cd3d/Tron_-_Icon_1_pnwjmc.png"
                : props.blockchain == "bnb"
                ? bnb_yellow
                : eth_purple
            }
            width="16"
            height="auto"
          />

          <Dropdownn
            heading="tokens"
            className={styles.stat}
            value={[
              `${
                tron || tron == 0 ? tron.toFixed(3) : ""
              } ${props.utils.blockchain.toUpperCase()}`,
              `${
                tron ? (tron * props.utils.currencies.USD).toFixed(2) : ""
              } USD`,
              `${
                tron ? (tron * props.utils.currencies.CHF).toFixed(2) : ""
              } CHF`,
              `${
                tron ? (tron * props.utils.currencies.EUR).toFixed(2) : ""
              } EUR`,
              `${
                tron ? (tron * props.utils.currencies.GIP).toFixed(2) : ""
              } GIP`,
              `${
                tron ? (tron * props.utils.currencies.KWD).toFixed(2) : ""
              } KWD`,
              `${
                tron ? (tron * props.utils.currencies.GBP).toFixed(2) : ""
              } GBP`,
              `${
                tron ? (tron * props.utils.currencies.JOD).toFixed(2) : ""
              } JOD`,
              `${
                tron ? (tron * props.utils.currencies.OMR).toFixed(2) : ""
              } OMR`,
              `${
                tron ? (tron * props.utils.currencies.BHD).toFixed(2) : ""
              } BHD`,
              `${
                tron ? (tron * props.utils.currencies.CAD).toFixed(2) : ""
              } CAD`,
              `${
                tron ? (tron * props.utils.currencies.INR).toFixed(2) : ""
              } INR`,
              `${
                tron ? (tron * props.utils.currencies.JPY).toFixed(2) : ""
              } JPY`,
              `${
                tron ? (tron * props.utils.currencies.HKD).toFixed(2) : ""
              } HKD`,
              `${
                tron ? (tron * props.utils.currencies.PAB).toFixed(2) : ""
              } PAB`,
              `${
                tron ? (tron * props.utils.currencies.AED).toFixed(2) : ""
              } AED`,
            ]}
            footer={[
              `${tron ? tron : ""} ${props.utils.blockchain.toUpperCase()}`,
              `${
                tron ? (tron * props.utils.currencies.USD).toFixed(2) : ""
              } USD`,
            ]}
          />
          <Tooltip
            title={`${props.utils.blockchain.toUpperCase()} value of your ${
              props.blockchain == "eth"
                ? "ECETO after penalty"
                : props.blockchain == "bnb"
                ? "BCETO after penalty"
                : "CETO"
            }`}
          >
            <IconButton color="primary" style={{ padding: 0 }}>
              <HelpOutlineIcon
                style={{
                  color: getColor(2),
                  width: "18px",
                  height: "18px",
                  margin: "0 10px",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>

        <GreenButton
          style={{ background: getColor(1), color: "#ffffff" }}
          className={styles.lt}
          actionbar={true}
          onClick={() =>
            history.push({
              pathname:
                props.blockchain == "eth"
                  ? "/eceto/buy"
                  : props.blockchain == "bnb"
                  ? "/bceto/buy"
                  : "/ceto/buy",
            })
          }
        >
          BUY{" "}
          {props.blockchain == "eth"
            ? "ECETO"
            : props.blockchain == "bnb"
            ? "BCETO"
            : "CETO"}
        </GreenButton>

        <div className={styles.with}>
          <GreenButton
            style={{
              background: "transparent",
              color: getColor(3),
              border: `1px solid ${getColor(3)}`,
              boxShadow: "none",
            }}
            className={styles.l}
            onClick={() =>
              history.push({
                pathname:
                  props.blockchain == "eth"
                    ? "/eceto/buy"
                    : props.blockchain == "bnb"
                    ? "/bceto/buy"
                    : "/ceto/buy",
              })
            }
          >
            Reinvest
          </GreenButton>{" "}
        </div>
        <GreenButton
          style={{ background: getColor(4), color: getColor(5) }}
          className={styles.lt}
          actionbar={true}
          onClick={() =>
            history.push({
              pathname:
                props.blockchain == "eth"
                  ? "/eceto/sell"
                  : props.blockchain == "bnb"
                  ? "/bceto/sell"
                  : "/ceto/sell",
            })
          }
        >
          SELL{" "}
          {props.blockchain == "eth"
            ? "ECETO"
            : props.blockchain == "bnb"
            ? "BCETO"
            : "CETO"}
        </GreenButton>

        <a
          className={`${styles.link}  ${styles.with}`}
          style={{ color: getColor(3) }}
          onClick={() =>
            history.push({
              pathname:
                props.blockchain == "eth"
                  ? "/eceto/withdraw"
                  : props.blockchain == "bnb"
                  ? "/bceto/withdraw"
                  : "/ceto/withdraw",
            })
          }
        >
          withdraw
        </a>
        <div className={`${styles.action_stat} ${styles.l}`}>
          <img
            src={props.blockchain == "bnb" ? contract_img2 : contract_img}
            width="24"
            height="24"
          />
          <span className={styles.stat}>
            {props.blockchain == "trx" && contractBalance
              ? Formatter(contractBalance / 1e6)
              : props.blockchain == "eth" && contractBalance
              ? parseFloat(contractBalance).toFixed(3)
              : props.blockchain == "bnb" &&
                (contractBalance || contractBalance == 0)
              ? parseFloat(contractBalance).toFixed(3)
              : ""}{" "}
            {props.blockchain.toUpperCase()} in contract
          </span>
        </div>
        {page == 1 ? (
          <a
            href="https://www.youtube.com/channel/UCNRN6hw26z6TGWiZpAk5XXQ"
            target="_blank"
            className={`${styles.visit} ${styles.l}`}
            style={{ color: getColor(2) }}
          >
            Youtube <LaunchIcon style={{ margin: "-5px 0" }}></LaunchIcon>
          </a>
        ) : (
          <div className={`${styles.action_stat} ${styles.l}`}>
            <img
              src={props.blockchain == "bnb" ? trximage2 : trximage}
              width="24"
              height="24"
            />
            <span className={styles.stat}>
              {trxPerToken && parseFloat(trxPerToken).toFixed(3)}{" "}
              {props.utils.blockchain.toUpperCase()}/
              {props.blockchain == "eth"
                ? "ECETO"
                : props.blockchain == "bnb"
                ? "BCETO"
                : "CETO"}
            </span>
            <Tooltip
              title={`Buy price of 1 ${
                props.blockchain == "eth"
                  ? "ECETO"
                  : props.blockchain == "bnb"
                  ? "BCETO"
                  : "CETO"
              }`}
            >
              <IconButton
                color="primary"
                style={{ padding: 0, marginLeft: "10px" }}
              >
                <HelpOutlineIcon
                  style={{
                    color: getColor(2),
                    width: "18px",
                    height: "18px",
                    margin: "0 10px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Actionbar);
