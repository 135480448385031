import React, { useEffect, useState } from "react";
// import { browserHistory } from "react-router";
// import { Menu } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import Contract_obj from "./../../getTronweb";
import styles from "./sub-navbar.module.scss";
import Snackbar from "@material-ui/core/Snackbar";
import copy_icon from "../../public/Group 5212.png";
import metacoinConfig from "../../metacoin-config.js";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as actions from "../../actions";

import web3_obj from "../../getWeb3.js";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

// const { SubMenu } = Menu;

const SubNavbar = ({ step, history, updated, flag, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState("mail");
  const [address, setAddress] = useState();
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accBalance, setAccBalance] = useState();
  const [node, setNode] = useState();
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const menu = (
    <Menu
      style={{
        backgroundColor:
          props.blockchain == "eth"
            ? "#AFB0B0"
            : props.blockchain == "bnb"
            ? "#691AAA"
            : "#009ee4",
        color: props.blockchain == "eth" ? "black" : "white",
      }}
      className={styles.dropp}
    >
      <Menu.Item>
        <a
          style={{ color: props.blockchain == "eth" ? "black" : "white" }}
          rel="noopener noreferrer"
          onClick={() => history.push({ pathname: "buy" })}
        >
          Buy/Reinvest
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{ color: props.blockchain == "eth" ? "black" : "white" }}
          rel="noopener noreferrer"
          onClick={() => history.push({ pathname: "sell" })}
        >
          Sell/Withdraw
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{ color: props.blockchain == "eth" ? "black" : "white" }}
          rel="noopener noreferrer"
          onClick={() => history.push({ pathname: "transfer" })}
        >
          Transfer
        </a>
      </Menu.Item>
      {/* <Menu.Item>
        <a
          style={{ color: "white" }}
          rel="noopener noreferrer"
          href="/transactions"
        >
          Transactions
        </a>
      </Menu.Item> */}
    </Menu>
  );
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const vertical = "top";
  const horizontal = "center";
  const toggleMarketPlaceDropdown = e => {
    setIsOpen(isOpen => !isOpen);
  };

  const trxData = async () => {
    console.log("test", Contract_obj.tronWebProvider, Contract_obj.accounts[0]);
    var obj = setInterval(async () => {
      // console.log("kk");

      if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
        clearInterval(obj);
        var node_ =
          window.tronWeb &&
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : window.tronWeb.fullNode.host == "https://api.trongrid.io"
            ? "mainnet"
            : metacoinConfig["private"].fullHost;
        setNode(node_);
        window.tronWeb &&
          (await window.tronWeb.trx
            .getBalance(window.tronWeb.defaultAddress.base58)
            .then(result => {
              console.log(result, "balance");
              setAccBalance(result);
            }));
        setAddress(window.tronWeb.defaultAddress.base58);
      }
    }, 2);

    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action == "setNode") {
        console.log("setNode event", e.data.message);

        if (
          window.tronWeb &&
          window.tronWeb.fullNode.host == metacoinConfig.fullHost
        ) {
          setAddress(Contract_obj.accounts[0]);
        }
      }
      if (e.data.message && e.data.message.action == "setAccount") {
        setAddress(Contract_obj.accounts[0]);
      }
    });
    setAddress(Contract_obj.accounts[0]);
  };
  const bnbethData = async () => {
    console.log(props.utils.blockchain, web3_obj.networkId);
    if (
      (props.utils.blockchain == "eth" &&
        (web3_obj.networkId == 1 || web3_obj.networkId == 3)) ||
      (props.utils.blockchain == "bnb" && web3_obj.networkId == 97) ||
      web3_obj.networkId == 56
    ) {
      var obj = setInterval(async () => {
        if (web3_obj && web3_obj.accounts[0] && web3_obj.accounts[0][0]) {
          console.log(web3_obj);
          setAddress(web3_obj.accounts[0][0]);
          var c_balance = await web3_obj.web3_instance.eth.getBalance(
            web3_obj.accounts[0][0]
          );
          setAccBalance(c_balance);
          clearInterval(obj);
        }
      }, 2000);
    } else {
      setAddress();
      setAccBalance();
    }
  };
  useEffect(() => {
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    props.utils.blockchain == "eth" || props.utils.blockchain == "bnb"
      ? bnbethData()
      : trxData();
  }, [Contract_obj, updated, props.utils.blockchain]);
  const getColor = step_ => {
    if (step == step_) {
      if (props.blockchain == "trx") {
        return "#FFFFFF";
      } else if (props.blockchain == "bnb") {
        return "#FFFFFF";
      } else {
        return "#000000";
      }
    } else {
      if (props.blockchain == "trx") {
        return "#C9E8FF";
      } else if (props.blockchain == "bnb") {
        return "#C3A3DD";
      } else {
        return "#696A6A";
      }
    }
  };
  return (
    <div className={styles.mainDiv}>
      {/* <div>
      Please reset your auto-reinvestment setup
    </div> */}
      <div
        className={`${styles.subNavbar} ${!address && styles.centered}`}
        style={{
          display: flag == 0 && "none",
          background:
            props.blockchain == "eth"
              ? "#AFB0B0"
              : props.blockchain == "bnb"
              ? "#691AAA"
              : "#009EE4",
        }}
      >
        {/* <p
          style={{ color: step == 1 ? "white" : "#C9E8FF" }}
          data-style-attr={step == 1 && "selected"}
          onClick={() => history.replace({ pathname: "/wrapped-ceto" })}
          className={`${styles.l}`}
        >
          Wrapped Ceto
        </p> */}

        <Dropdown overlay={menu}>
          <a
            style={{ color: getColor(1) }}
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
          >
            Exchange{" "}
            {props.blockchain == "trx"
              ? "CETO"
              : props.blockchain == "eth"
              ? "ECETO"
              : "BCETO"}{" "}
          </a>
        </Dropdown>

        <p
          style={{
            color: getColor(2),
          }}
          data-style-attr={step == 2 && "selected"}
          className={`${styles.l}`}
          onClick={() => history.push({ pathname: "/referral" })}
        >
          Referral
        </p>
        <p
          style={{ color: getColor(5) }}
          className={`${styles.l} ${styles.flex1}`}
          // onClick={() =>
          //   node
          //     ? window.open(
          //         `${metacoinConfig[node].url}/#/contract/${metacoinConfig[node].contractAddressBase}/code`
          //       )
          //     : window.open(
          //         `${metacoinConfig["mainnet"].url}/#/contract/${metacoinConfig["mainnet"].contractAddressBase}/code`
          //       )
          // }
        >
          {/* <span onClick={() => history.replace({ pathname: "/transactions" })}>
            Transactions
          </span> */}
        </p>
        {address && (
          <p
            className={`${styles.s} ${styles.address}`}
            style={{ color: getColor(step) }}
          >
            MY Wallet Balance:{" "}
            {address ? (
              <span style={{ color: getColor(-1) }}>
                {accBalance && parseFloat(accBalance / factor).toFixed(2)}{" "}
                {props.utils.blockchain && props.utils.blockchain.toUpperCase()}
              </span>
            ) : (
              <span>Not connected</span>
            )}
          </p>
        )}
        {window.tronWeb && window.tronWeb.defaultAddress.base58 ? (
          <p className={`${styles.address}`} style={{ color: getColor(step) }}>
            MY{" "}
            {props.blockchain == "eth"
              ? "ECETO"
              : props.blockchain == "bnb"
              ? "BCETO"
              : "CETO"}{" "}
            Address:{" "}
            <span style={{ color: getColor(-1) }}>
              {window.tronWeb.defaultAddress.base58.toString().slice(0, 5)}...
            </span>
          </p>
        ) : (
          <p
            className={` ${styles.address}`}
            style={{ color: props.blockchain == "eth" ? "black" : "white" }}
          >
            <span
              style={{ color: props.blockchain == "eth" ? "black" : "white" }}
            >
              <ErrorIcon style={{ margin: "-6px 8px" }}></ErrorIcon>
              {props.utils.blockchain == "eth" ||
              props.utils.blockchain == "bnb"
                ? "Metamask"
                : "Tronlink"}{" "}
              Not connected
            </span>
          </p>
        )}

        {address && (
          <CopyToClipboard
            text={address && address}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 2000);
            }}
          >
            <button className={`${styles.eachlink} `}>
              <img
                src={copy_icon}
                alt=""
                style={{ filter: props.blockchain == "eth" && "invert(1)" }}
              />
              <span style={{ marginRight: "20px", color: getColor(step) }}>
                Copy
              </span>
            </button>
          </CopyToClipboard>
        )}
        {copied && <div className={styles.snackbar}>Copied</div>}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(SubNavbar);
