import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Badge } from "antd";
import "antd/dist/antd.css";
import Hourglass_eth from "../../Hourglass_eth.json";
import Hourglass_bnb from "../../Hourglass_bnb.json";

import {
  Style,
  StyleDrawer,
  Switch,
  SwitchLabel,
  SwitchRadio,
  SwitchSelection,
} from "./style.js";
import menu_img from "../../public/Menu.svg";
import logo from "../../public/PNG transparent file 03@2x.png";
import logo_blue from "../../public/logo_blue.png";
import eceto_logo from "../../public/ECETO-Logo.png";
import bceto_logo from "../../public/BCETO-Logo.png";
import { withRouter } from "react-router-dom";
import metacoinConfig from "../../metacoin-config";
// import { Switch } from "antd";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";
import Contract_obj from "./../../getTronweb";
import web3_obj from "../../getWeb3.js";

const social_menu = (
  <Menu
  // style={{ backgroundColor: "#009ee4", color: "white" }}
  // className={styles.dropp}
  >
    <Menu.Item>
      <a
        style={{
          fontFamily: "Poppins",
          padding: "10px 20px",
          color: "#435475",
          fontWeight: "500",
        }}
        rel="noopener noreferrer"
        href="https://discord.com/invite/Jxg8GzZ3kq"
        target="_blank"
      >
        Discord
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        style={{
          fontFamily: "Poppins",
          padding: "10px 20px",
          color: "#435475",
          fontWeight: "500",
        }}
        rel="noopener noreferrer"
        href="https://t.me/cetoken"
        target="_blank"
      >
        Telegram
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        style={{
          fontFamily: "Poppins",
          padding: "10px 20px",
          color: "#435475",
          fontWeight: "500",
        }}
        rel="noopener noreferrer"
        href="/social"
      >
        Twitter
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        style={{
          fontFamily: "Poppins",
          padding: "10px 20px",
          color: "#435475",
          fontWeight: "500",
        }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://medium.com/@CETOFoundation"
      >
        Blog
      </a>
    </Menu.Item>
  </Menu>
);

const Navbar = ({ homepage, page, history, ...props }) => {
  const [isMenuBarOpen, setIsMenuBarOpen] = useState(false);
  const menu = (
    <Menu
    // style={{ backgroundColor: "#009ee4", color: "white" }}
    // className={styles.dropp}
    >
      <Menu.Item>
        <a
          style={{
            fontFamily: "Poppins",
            padding: "10px 20px",
            color: "#435475",
            fontWeight: "500",
          }}
          rel="noopener noreferrer"
          target="_blank"
          href={`https://github.com/ceto-code/ceto-contract`}
        >
          Github
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{
            fontFamily: "Poppins",
            padding: "10px 20px",
            color: "#435475",
            fontWeight: "500",
          }}
          rel="noopener noreferrer"
          onClick={() =>
            window.open(
              `https://etherscan.io/address/${Hourglass_eth.networks[1].address}`
            )
          }
        >
          Etherscan
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{
            fontFamily: "Poppins",
            padding: "10px 20px",
            color: "#435475",
            fontWeight: "500",
          }}
          rel="noopener noreferrer"
          onClick={() =>
            window.open(
              `https://bscscan.com/address/${Hourglass_bnb.networks[56].address}`
            )
          }
        >
          Bscscan
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          style={{
            fontFamily: "Poppins",
            padding: "10px 20px",
            color: "#435475",
            fontWeight: "500",
          }}
          rel="noopener noreferrer"
          onClick={() =>
            window.open(
              `${metacoinConfig["mainnet"].url}/#/contract/${metacoinConfig["mainnet"].contractAddressBase}/code`
            )
          }
        >
          Tronscan
        </a>
      </Menu.Item>
    </Menu>
  );
  const toggleMenuBar = e => {
    setIsMenuBarOpen(isMenuBarOpen => !isMenuBarOpen);
  };

  const handleChange = async e => {
    console.log(e);
    await props.setBlockchain(e);
  };
  const changeCurrencies = async () => {
    await props.setCurrencies(props.utils.blockchain);
  };
  useEffect(() => {
    changeCurrencies();
  }, [props.utils.blockchain]);
  useEffect(() => {}, []);

  return (
    <Style
      homepage={homepage}
      page={page}
      blockchain={props.blockchain && props.blockchain}
    >
      <div className={`navbarr ${homepage ? "" : "inner"}`}>
        <span className={`navbar__navbarLogo`}>
          <img
            className="loGo"
            src={
              homepage
                ? logo
                : props.blockchain == "eth"
                ? eceto_logo
                : props.blockchain == "bnb"
                ? bceto_logo
                : logo_blue
            }
            alt="logo"
            onClick={() => {
              history.replace("/");
            }}
          />
          {/* {!homepage && (
            <ToggleSwitch
              values={["trx", "eth", "bnb"]}
              selected={props.utils.blockchain}
              handleChange={handleChange}
            />
          )} */}
        </span>

        <span className={`navbar__navbarLinks`}>
          <a
            className={`link ${page == 2 ? "purple" : ""}`}
            data-style-attr="selected"
            onClick={() => history.push({ pathname: "/ceto/buy" })}
          >
            Exchange
          </a>
          <a
            className={`link ${page == 70 ? "purple" : ""}`}
            style={{ textTransform: "capitalize" }}
            target="_blank"
            href="https://opensea.io/accounts/Dottie"
          >
            NFTs
          </a>
          <Dropdown overlay={menu}>
            <a
              className={`link ${
                page == 6 ? "purple" : ""
              } ant-dropdown-link l`}
              onClick={e => e.preventDefault()}
            >
              Contract
            </a>
          </Dropdown>
          <Dropdown overlay={social_menu}>
            <a
              className={`link ${
                page == 9 ? "purple" : ""
              } ant-dropdown-link l`}
              onClick={e => e.preventDefault()}
            >
              Social
            </a>
          </Dropdown>

          <a className={`link ${page == 7 ? "purple" : ""}`} href="/whitepaper">
            Whitepaper
          </a>

          <a className={`link ${page == 12 ? "purple" : ""}`} href="/tutorial">
            Tutorial
          </a>
          <span>
            <IconButton onClick={toggleMenuBar}>
              {/* <MenuIcon style={{ fontSize: "32px", color: " #fff" }} /> */}
              <img
                className="ham"
                src={
                  homepage
                    ? menu_img
                    : "https://res.cloudinary.com/dgdjabqwr/image/upload/v1609933423/cd3d/Menu_1_tvudd2.png"
                }
                alt="menu"
              />
            </IconButton>
          </span>
        </span>

        {isMenuBarOpen && (
          <StyleDrawer
            anchor="right"
            open={isMenuBarOpen}
            onClose={toggleMenuBar}
            blockchain={props.blockchain}
          >
            <div
              style={{
                minHeight: "100%",
                background: props.blockchain == "trx" ? "#002E8C" : "black",
                width: "345px",
              }}
            >
              <MenuItem className="navbar__drawerLink">
                <CloseIcon
                  onClick={toggleMenuBar}
                  style={{ color: "#ffffff" }}
                />
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 4 ? "white_" : ""} s`}
                onClick={() => history.replace({ pathname: "/referral" })}
              >
                Referral
              </MenuItem>
              {/* <MenuItem
                className={`navbar__drawerLink ${page == 5 ? "white_" : ""} s`}
                onClick={() => history.replace({ pathname: "/transactions" })}
              >
                Transactions
              </MenuItem> */}
              <MenuItem
                className={`navbar__drawerLink ${page == 6 ? "white_" : ""}`}
                onClick={() => history.replace({ pathname: "/whitepaper" })}
              >
                Whitepaper
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 7 ? "white_" : ""}`}
                onClick={() =>
                  window.open(`https://github.com/ceto-code/ceto-contract`)
                }
              >
                Github
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 8 ? "white_" : ""}`}
                onClick={() =>
                  window.open(
                    `${metacoinConfig["mainnet"].url}/#/contract/${metacoinConfig["mainnet"].contractAddressBase}/code`
                  )
                }
              >
                Tronscan
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 8 ? "white_" : ""}`}
                onClick={() =>
                  window.open(
                    `https://etherscan.io/address/${Hourglass_eth.networks[1].address}`
                  )
                }
              >
                Etherscan
              </MenuItem>{" "}
              <MenuItem
                className={`navbar__drawerLink ${page == 8 ? "white_" : ""}`}
                onClick={() =>
                  window.open(
                    `https://bscscan.com/address/${Hourglass_bnb.networks[56].address}`
                  )
                }
              >
                Bscscan
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 9 ? "white_" : ""}`}
                onClick={() => history.replace({ pathname: "/social" })}
              >
                Twitter
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 10 ? "white_" : ""}`}
                onClick={() => window.open("https://discord.gg/Jxg8GzZ3kq")}
              >
                Discord
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 11 ? "white_" : ""}`}
                onClick={() => window.open("https://t.me/cetoken")}
              >
                Telegram
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 12 ? "white_" : ""}`}
                onClick={() => history.replace({ pathname: "/tutorial" })}
              >
                Tutorial
              </MenuItem>
              <MenuItem
                className={`navbar__drawerLink ${page == 7 ? "white_" : ""}`}
                onClick={() => history.replace({ pathname: "/faq" })}
              >
                FAQ
              </MenuItem>
            </div>
          </StyleDrawer>
        )}
      </div>
    </Style>
  );
};

// export default withRouter(Navbar);
const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Navbar);

const ClickableLabel = ({ title, onChange, id }) => (
  <SwitchLabel onClick={() => onChange(title)} className={id}>
    {title.toUpperCase()}
  </SwitchLabel>
);

const ConcealedRadio = ({ value, selected }) => (
  <SwitchRadio type="radio" name="switch" checked={selected === value} />
);

class ToggleSwitch extends React.Component {
  state = { selected: this.props.selected };

  handleChange = val => {
    this.setState({ selected: val });
  };

  selectionStyle = () => {
    return {
      left: `${(this.props.values.indexOf(this.state.selected) / 3) * 100}%`,
    };
  };

  render() {
    const { selected } = this.state;
    return (
      <Switch>
        {this.props.values.map(val => {
          return (
            <span>
              <ConcealedRadio value={val} selected={selected} />
              <ClickableLabel
                title={val}
                onChange={e => {
                  this.handleChange(e);
                  this.props.handleChange(e);
                }}
              />
            </span>
          );
        })}
        <SwitchSelection style={this.selectionStyle()} />
      </Switch>
    );
  }
}
