import React, { useState, useEffect } from "react";
import axios from "axios";
import Dividends from "../dividends/dividends.component";
import Dividendss from "../dividends/dividends_";
import axiosInstance from "../../config/axios";

import { connect } from "react-redux";
import * as actions from "../../actions";
import Contract_obj from "../../getTronweb";
import metacoinConfig from "../../metacoin-config.js";
import styles from "./holdings.module.scss";
import web3_obj from "../../getWeb3";

const Holdings = ({
  theme,
  contract,
  accounts,
  updated,
  setUpdated,
  ...props
}) => {
  const [tokens, setTokens] = useState();
  const [dividends, setDividends] = useState();
  const [balance, setBalance] = useState();

  const [cetoIfReinvested, setCetoIfReinvested] = useState();
  const [usd, setUsd] = useState();
  const [currenyArray, setCurrenyArray] = useState([]);
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const trxData = async force => {
    axiosInstance
      .get(
        `/api/cache/?function=myTokens&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : window.tronWeb.fullNode.host == "https://api.trongrid.io"
            ? "mainnet"
            : metacoinConfig["private"].fullHost
        }&contract_address=${
          metacoinConfig["private"].contractAddressBase
        }&sender_address=${Contract_obj.tronWebProvider.defaultAddress.base58}`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "ressp");
        var data = resp.data.data;
        setTokens(data);

        if (parseFloat(data) == 0) {
          setBalance(0);
        } else {
          axiosInstance
            .get(
              `/api/cache/?function=tokensToTron_&expiry=30&force=${force}&arguments=${parseInt(
                data
              )}&network=${
                window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
                  ? "shasta"
                  : window.tronWeb.fullNode.host == "https://api.trongrid.io"
                  ? "mainnet"
                  : metacoinConfig["private"].fullHost
              }&contract_address=${
                metacoinConfig["private"].contractAddressBase
              }&sender_address=${
                Contract_obj.tronWebProvider.defaultAddress.base58
              }`,
              {
                timeout: 50000,
              }
            )
            .then(resp => {
              console.log(resp.data.data, "setTron");

              setBalance(resp.data.data);
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
      });

    axiosInstance
      .get(
        `/api/cache/?function=calculateTokensReinvested&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : window.tronWeb.fullNode.host == "https://api.trongrid.io"
            ? "mainnet"
            : metacoinConfig["private"].fullHost
        }&contract_address=${
          metacoinConfig["private"].contractAddressBase
        }&sender_address=${Contract_obj.tronWebProvider.defaultAddress.base58}`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "setCetoIfReinvested");

        setCetoIfReinvested(resp.data.data);
      })
      .catch(err => {
        console.log(err);
      });

    axiosInstance
      .get(
        `/api/cache/?function=myDividends&arguments=True&expiry=30&force=${force}&network=${
          window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
            ? "shasta"
            : window.tronWeb.fullNode.host == "https://api.trongrid.io"
            ? "mainnet"
            : metacoinConfig["private"].fullHost
        }&contract_address=${
          metacoinConfig["private"].contractAddressBase
        }&sender_address=${Contract_obj.tronWebProvider.defaultAddress.base58}`,
        {
          timeout: 50000,
        }
      )
      .then(resp => {
        console.log(resp.data.data, "setCetoIfReinvested");

        setDividends(resp.data.data.toString());
      })
      .catch(err => {
        console.log(err);
      });
  };
  const ethData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 1 || web3_obj.networkId == 3)
    ) {
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      console.log(my_tokens, "my_tokens");
      setTokens(parseFloat(my_tokens));
      if (my_tokens == 0) {
        setBalance(0);
      } else {
        var tokensToEthereum_ = await web3_obj.contract_instace.methods
          .calculateEthereumReceived(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        setBalance(parseFloat(tokensToEthereum_));
      }
      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(false)
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseFloat(myDividends));

      var res = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setCetoIfReinvested(parseFloat(res));
    }
  };
  const bnbData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.contract_instace &&
      (web3_obj.networkId == 97 || web3_obj.networkId == 56)
    ) {
      var myDividends = await web3_obj.contract_instace.methods
        .myDividends(true)
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setDividends(parseFloat(myDividends));
      console.log("myDividends", myDividends);

      var res = await web3_obj.contract_instace.methods
        .calculateTokensReinvested()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });
      setCetoIfReinvested(parseFloat(res));
      var my_tokens = await web3_obj.contract_instace.methods
        .myTokens()
        .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

      setTokens(parseFloat(my_tokens));
      console.log("setTokens", my_tokens);

      if (my_tokens == 0) {
        setBalance(0);
        console.log("setBalancee", 0);
      } else {
        var res = await web3_obj.contract_instace.methods
          .calculateEthereumReceived(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: Contract_obj.feeLimit });

        setBalance(parseFloat(res));
        console.log("setBalancee", res);
      }

      // console.log("setCetoIfReinvested", res);
    }
  };
  useEffect(() => {
    if (props.utils.blockchain == "eth") {
      ethData();
    } else if (props.utils.blockchain == "bnb") {
      bnbData();
    } else {
      Contract_obj.contract_instace && window.tronWeb && trxData(0);
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            Contract_obj.contract_instace && window.tronWeb && trxData(0);
          }
        }
        if (e.data.message && e.data.message.action == "setAccount") {
          Contract_obj.contract_instace && window.tronWeb && trxData(0);
        }
      });
      window.addEventListener("load", async event => {
        console.log("loaded");
        var obj1 = setInterval(async () => {
          if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
            console.log("loadeded");

            clearInterval(obj1);
            window.tronWeb && trxData(1);
          }
        }, 2000);
      });
    }
    setTimeout(() => {
      console.log("vdbv");
      window.tronWeb && trxData(0);
    }, 3000);
  }, []);

  useEffect(() => {
    // setFactor(
    //   props.utils.blockchain == "eth"
    //     ? 1e18
    //     : props.utils.blockchain == "bnb"
    //     ? 1e18
    //     : 1e6
    // );
    props.utils.blockchain == "eth"
      ? ethData()
      : props.utils.blockchain == "bnb"
      ? bnbData()
      : window.tronWeb && trxData(1);
  }, [
    updated,
    factor,
    props.utils.blockchain,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
  ]);
  return (
    <div className={styles.holdings}>
      <span
        className={styles.holdings__title}
        style={{
          color:
            props.blockchain == "eth"
              ? "#464646"
              : props.blockchain == "bnb"
              ? "#BF9C00"
              : "#002E8C",
        }}
      >
        Holdings
      </span>

      <div className={styles.holdingsCards}>
        <Dividends
          blockchain={props.blockchain}
          heading="tokens"
          value={`${tokens ? (tokens / factor).toFixed(3) : " "} ${
            props.blockchain == "eth"
              ? "ECETO"
              : props.blockchain == "bnb"
              ? "BCETO"
              : "CETO"
          }`}
          footer={[
            `${
              balance ? (balance / factor).toFixed(3) : ""
            } ${props.blockchain.toUpperCase()}`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              balance
                ? ((balance / factor) * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
        />
        <Dividendss
          blockchain={props.blockchain}
          heading="dividends"
          value={`${
            dividends ? (dividends / factor).toFixed(3) : ""
          } ${props.blockchain.toUpperCase()}`}
          footer={[
            `${
              cetoIfReinvested ? (cetoIfReinvested / factor).toFixed(2) : " "
            } ${
              props.blockchain == "eth"
                ? "ECETO"
                : props.blockchain == "bnb"
                ? "BCETO"
                : "CETO"
            } (if reinvested)`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.USD).toFixed(2)
                : ""
            } USD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.CHF).toFixed(2)
                : ""
            } CHF`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.EUR).toFixed(2)
                : ""
            } EUR`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.GIP).toFixed(2)
                : ""
            } GIP`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.KWD).toFixed(2)
                : ""
            } KWD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.GBP).toFixed(2)
                : ""
            } GBP`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.JOD).toFixed(2)
                : ""
            } JOD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.OMR).toFixed(2)
                : ""
            } OMR`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.BHD).toFixed(2)
                : ""
            } BHD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.CAD).toFixed(2)
                : ""
            } CAD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.INR).toFixed(2)
                : ""
            } INR`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.JPY).toFixed(2)
                : ""
            } JPY`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.HKD).toFixed(2)
                : ""
            } HKD`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.PAB).toFixed(2)
                : ""
            } PAB`,
            `${
              dividends
                ? ((dividends / factor) * props.utils.currencies.AED).toFixed(2)
                : ""
            } AED`,
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  // console.log(state, "state");
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(Holdings);
