import Hourglass from "./contracts/Hourglass.json";

import metacoinConfig from "./metacoin-config";

var contractAddress;
var tronWeb;
try {
  const TronWeb = require("tronweb");

  window.addEventListener("load", async event => {
    tronWeb = window.tronWeb;
    contract_obj.tronWebProvider = window.tronWeb;
    contract_obj.accounts = [window.tronWeb.defaultAddress.base58];
    console.log("page is fully loaded", window.tronWeb, tronWeb);
    var node_ =
      window.tronWeb &&
      (window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
        ? "shasta"
        : window.tronWeb.fullNode.host == "https://api.trongrid.io"
        ? "mainnet"
        : "private");
    if (node_) {
      contractAddress = metacoinConfig[node_].contractAddress;
      console.log(contractAddress, "contractAddress");
      contract_obj.init(node_);
    }
  });
  // document.write(window.tronWeb.defaultAddress.base58)
} catch (err) {
  // alert(`The app looks not configured. Please run "npm run migrate" ${err}`);
}

const contract_obj = {
  tronWebProvider: tronWeb,
  contracts: {},
  accounts: [],
  contractAddress: contractAddress,
  // privateKey:
  //   "CBB6FDC134599AD3FF5D8E4A23E87EB1289BC6985A3380D8FAA35CDBA677DA05",
  feeLimit: 100000000,
  callValue: 0,

  contract_instace: null,
  init: async function (node_) {
    console.log(node_, "NOnkccDE", metacoinConfig[node_]);

    if (node_) {
      this.contractAddress = metacoinConfig[node_].contractAddressBase;

      console.log(this.contractAddress, "mkln;v", window.tronWeb, tronWeb);
      window.addEventListener("load", async event => {
        tronWeb = window.tronWeb;
        contract_obj.tronWebProvider = window.tronWeb;
        console.log("page is fully loaded", window.tronWeb, tronWeb);
        this.contract_instace =
          window.tronWeb &&
          (await window.tronWeb
            .contract()
            .at(metacoinConfig[node_].contractAddress));
        console.log(this.contract_instace, "kfvmd;v");
      });
    }
    this.accounts = [
      this.tronWebProvider && this.tronWebProvider.defaultAddress.base58,
    ];
  },
  buyFunc: async function (trx, ref) {
    // await this.init()
    var ref_by = ref ? ref : this.accounts[0];
    // console.log(this.contract_instace, "nnnn", trx, ref_by , this.accounts[0]);
    var data = await this.contract_instace
      .buy(ref_by)
      .send({
        feeLimit: this.feeLimit,
        callValue: trx,
        // shouldPollResponse : true
      })
      .then(function (res) {
        if (res) {
          // console.log(res, "tt11");
        }
      });
  },
  approveFunc: async function (ceto, wrapped_ceto_address) {
    var data = await this.contract_instace
      .approve(wrapped_ceto_address, ceto)
      .send({
        feeLimit: this.feeLimit,
        from: this.accounts[0],
      })
      .then(function (res) {
        if (res) {
          console.log(res, "tt11");
        }
      })
      .catch(err => console.log(err));
  },
  refFunc: async function (hex_str) {
    console.log("ref_str", typeof hex_str);
    await this.contract_instace
      .setReferralName(hex_str)
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          // console.log(res.toString(), "refff");
        }
      });
  },
  reinvestFunc: async function (
    autoReinvest,
    period,
    rewardPerInvocation,
    minimumDividendValue
  ) {
    const period_ = period;
    const rewardPerInvocation_ = rewardPerInvocation * 1e6;
    const minimumDividendValue_ = minimumDividendValue * 1e6;

    await this.contract_instace
      .reinvest(
        autoReinvest,
        period_,
        rewardPerInvocation_,
        minimumDividendValue_
      )
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          // console.log(res.toString(), "reinvest");
          return res;
        }
      });
  },
  stopAutoReinvestFunc: async function () {
    await this.contract_instace
      .stopAutoReinvest()
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          return res;

          // console.log(res.toString(), "stopAutoReinvestFunc");
        }
      });
  },
  withdrawFunc: async function () {
    await this.contract_instace
      .withdraw()
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          // console.log(res.toString(), "withdraw");
        }
      });
  },
  sellFunc: async function (ceto) {
    console.log(ceto, "ll");
    var ceto_to_sell = ceto * 1e6;
    await this.contract_instace
      .sell(ceto_to_sell)
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          // console.log(res.toString(), "sell");
        }
      });
  },
  transferFunc: async function (address, ceto) {
    var ceto_to_transfer = ceto * 1e6;

    await this.contract_instace
      .transfer(address, ceto_to_transfer)
      .send({
        feeLimit: this.feeLimit,
      })
      .then(function (res) {
        if (res) {
          console.log(res.toString(), "transferred");
        }
      });
  },
  getTokensFunc: async function () {
    var my_tokens = await this.contract_instace
      .myTokens()
      .call({
        from: this.accounts[0],
        feeLimit: this.feeLimit,
        callValue: this.callValue || 0,
      })
      .then(function (res) {
        if (res) {
          return Promise.resolve(res.toString());
        }
      });
  },
};

export default contract_obj;
