import styled from "styled-components";

import { Drawer } from "@material-ui/core";

export const Style = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  background: ${props =>
    props.homepage
      ? "#002e8c"
      : props.blockchain == "eth"
      ? "#000000"
      : props.blockchain == "bnb"
      ? "#1E1E1E"
      : "white"};
  margin: 0;
  border-radius: 0;
  font-family: Poppins;
  position: fixed;
  width: 100vw;
  z-index: 100;
  top: ${props => (props.page != 1 ? "40px" : "0")};
  /* position: fixed;
    width: 100%;
    z-index:100; */
  @media screen and (max-width: 600px) {
    height: 70px;
    padding: 1em;
    top: 0;
    .ham {
      width: 40px;
    }
  }

  .navbarr {
    display: flex;
    margin-bottom: 0 !important;
    width: 100%;
  }

  .navbar__navbarLogo {
    display: flex;
    .loGo {
      width: 120px;
      height: 50px;
      margin: auto;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        width: 120px !important;
        margin: auto;
      }
    }
    .ant-switch {
      background: rgb(105, 26, 170);
      transform: scale(1.5);
    }
    .ant-switch-checked {
      background-color: #002e8c;
    }
    .currency {
      font-family: Poppins;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .navbar__navbarLinks {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #a1b5e0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 3.2px;

    .link,
    .new {
      @media screen and (max-width: 1280px) {
        display: none;
      }
      color: ${props =>
        props.homepage
          ? "#A1B5E0"
          : props.blockchain == "eth"
          ? "#ffffff66"
          : props.blockchain == "bnb"
          ? "#ffffff66"
          : "#009EE4"};
    }

    .MuiSvgIcon-root {
      font-size: 32px;
      color: #fff;
    }
    .new {
      margin-right: 5rem;
      a {
        cursor: pointer;
        transition: color 0.2s ease;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          color: ${props => (props.homepage ? "white" : "#691AAA")};
        }
      }

      .ant-badge-count,
      .ant-badge-dot,
      .ant-badge .ant-scroll-number-custom-component {
        top: -5px;
        right: -20px;
      }
    }

    & > a {
      cursor: pointer;
      transition: color 0.2s ease;
      text-decoration: none;

      &:hover {
        color: ${props =>
          props.homepage
            ? "white"
            : props.blockchain == "eth"
            ? "white"
            : props.blockchain == "bnb"
            ? "#ECC100"
            : "#691AAA"};
      }
    }

    & > a:not(:last-child) {
      margin-right: 4em;
    }

    @media screen and (max-width: 1600px) {
      font-size: 14px;

      .MuiSvgIcon-root {
        font-size: 24px;
      }

      & > a:not(:last-child) {
        margin-right: 3em;
      }
    }
  }
  .inner {
    .navbar__navbarLinks {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #a1b5e0;
      text-transform: uppercase;
      font-weight: 600;

      font-size: 16px;
      letter-spacing: 3.2px;
    }
  }
  .purple {
    color: ${props =>
      props.homepage
        ? "white"
        : props.blockchain == "eth"
        ? "white"
        : props.blockchain == "bnb"
        ? "#ECC100"
        : "#691AAA"} !important;
    font-weight: bold;
  }
  .l {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const StyleDrawer = styled(Drawer)`
  .navbar__drawerLink {
    padding: 32px 1.4em;
    min-width: 220px;
    font-family: Poppins;
    background: ${props => (props.blockchain == "trx" ? "#002e8c" : "black")};

    text-align: right;
    justify-content: flex-end;
    color: ${props => (props.blockchain == "trx" ? " #7689b0" : "#ffffff75")};
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    border-bottom: 1px solid
      ${props => (props.blockchain == "trx" ? "#002e8c" : "#ffffff29")};
    font-weight: 600;

    @media screen and (max-width: 600px) {
      padding: 20px 1.4em;
    }
  }
  .navbar__drawerLink:hover {
    background: ${props =>
      props.blockchain == "trx" ? "#002e8ce8" : "#ffffff12"};
  }
  .s {
    @media screen and (min-width: 601px) {
      display: none;
    }
  }
  .white_ {
    color: #fff;
  }
  & > .navbar__drawerLink:first-child {
    border-top: 1px solid #a1b5e0;
  }

  @media screen and (max-width: 1600px) {
    padding: 20px 1em;
  }
`;

export const Switch = styled.div`
  margin: auto 20px;
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  position: relative;
  height: 26px;
  width: 135px;
  background-color: #e4e4e4;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
`;

export const SwitchRadio = styled.input`
  display: none;
`;

export const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 45px;
  height: 26px;
  background: #216ba5;
  border-radius: 3px;
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`
  position: relative;
  z-index: 2;
  float: left;
  width: 45px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  cursor: pointer;

  ${SwitchRadio}:checked + & {
    transition: 0.15s ease-out;
    color: #fff;
  }
`;
