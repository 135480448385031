import React, { useEffect, useState } from "react";
import styles from "./reinvestdividends.module.scss";
import Dropdown from "../dropdown/dropdown.component";
import GreenButton from "../cinema-draft-button/cinema-draft-button.component";
import arrow from "../../public/Button - Icon.svg";
import dividends_img from "../../public/Dividends - Icon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Contract_obj from "../../getWrappedContract";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Withdrawpopup from "../withpopup/withpopup";
import Reinvestpopup from "../reinvestpopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import AutoReinvestModal from "../autoreinvest";
import metacoinConfig from "../../metacoin-config.js";
import ConnectionModal from "../connectionpopup";
import contract_img from "../../public/TRX in contract - Icon.svg";
import web3_obj from "../../getWrappeddContractWeb3";
const WrappedHoldings = ({ updated, setUpdated, timer, ...props }) => {
  const [ceto, setCeto] = useState();
  const [wceto, setWceto] = useState();
  const [rate, setRate] = useState();
  const [factor, setFactor] = useState(
    props.utils.blockchain == "eth"
      ? 1e18
      : props.utils.blockchain == "bnb"
      ? 1e18
      : 1e6
  );
  const fetchData = async () => {
    console.log("inn");
    window.tronWeb &&
      (await Contract_obj.init(
        window.tronWeb.fullNode.host == "https://api.shasta.trongrid.io"
          ? "shasta"
          : window.tronWeb.fullNode.host == "https://api.trongrid.io"
          ? "mainnet"
          : metacoinConfig["private"].fullHost
      ));
    Contract_obj.contract_instace &&
      (await Contract_obj.contract_instace
        .balanceOf(Contract_obj.accounts[0])
        .call({
          from: Contract_obj.accounts[0],
          feeLimit: Contract_obj.feeLimit,
        })
        .then(async res => {
          if (res) {
            console.log(res.toString(), "balanceOf wceto");
            setWceto(parseInt(res.toString()) / factor);
            Contract_obj.contract_instace &&
              (await Contract_obj.contract_instace
                .cetoTokensTransfered_(res.toString())
                .call({
                  from: Contract_obj.accounts[0],
                  feeLimit: Contract_obj.feeLimit,
                })
                .then(function (res) {
                  if (res) {
                    console.log(res.toString(), "CETO TOKENS");
                    setCeto(parseInt(res.toString()) / factor);
                  }
                }));
          }
        }));
    Contract_obj.contract_instace &&
      (await Contract_obj.contract_instace
        .getWrappedCetoRate()
        .call({
          from: Contract_obj.accounts[0],
          feeLimit: Contract_obj.feeLimit,
        })
        .then(function (res) {
          if (res) {
            console.log(res.toString(), "getWrappedCetoRatee");
            setRate(parseInt(res.toString()) / 1e3);
          }
        }));
  };

  const ethData = async () => {
    if (
      web3_obj &&
      web3_obj.accounts[0] &&
      web3_obj.accounts[0][0] &&
      web3_obj.contract_instace &&
      ((props.utils.blockchain == "eth" &&
        [1, 3].includes(web3_obj.networkId)) ||
        (props.utils.blockchain == "bnb" &&
          [97, 56].includes(web3_obj.networkId)))
    ) {
      var my_tokens = await web3_obj.contract_instace.methods
        .balanceOf(web3_obj.accounts[0][0])
        .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

      console.log(my_tokens, "wceto");
      setWceto(parseInt(my_tokens) / factor);
      if (parseInt(my_tokens)) {
        console.log("in");
        var my_ceto_tokens = await web3_obj.contract_instace.methods
          .cetoTokensTransfered_(my_tokens)
          .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

        console.log(my_ceto_tokens, "ceto");
        setCeto(parseInt(my_ceto_tokens) / factor);
        var rate_ = await web3_obj.contract_instace.methods
          .getWrappedCetoRate()
          .call({ from: web3_obj.accounts[0][0], gas: web3_obj.feeLimit });

        console.log(rate_, "rate_");
        setRate(parseInt(rate_) / 1e3);
      }
    }
  };
  useEffect(() => {
    if (props.utils.blockchain == "trx") {
      Contract_obj.contract_instace && fetchData();
      window.addEventListener("message", function (e) {
        if (e.data.message && e.data.message.action == "setNode") {
          console.log("setNode event", e.data.message);

          if (
            window.tronWeb &&
            window.tronWeb.fullNode.host == metacoinConfig.fullHost
          ) {
            Contract_obj.contract_instace && fetchData();
          }
        }
        if (e.data.message && e.data.message.action == "setAccount") {
          Contract_obj.contract_instace && fetchData();
        }
      });
    } else {
      ethData();
    }
  }, []);

  useEffect(() => {
    console.log("updated...");
    setWceto();
    setCeto();
    setRate();
    setFactor(
      props.utils.blockchain == "eth"
        ? 1e18
        : props.utils.blockchain == "bnb"
        ? 1e18
        : 1e6
    );
    if (props.utils.blockchain == "trx") {
      Contract_obj.contract_instace && fetchData();
    } else {
      ethData();
    }
  }, [
    updated,
    window && window.tronWeb && window.tronWeb.defaultAddress.hex,
    props.utils.blockchain,
  ]);

  return (
    <div className={styles.right}>
      <div className={styles.boxx}>
        <div className={styles.imgg}>
          <img src={contract_img} width="32" height="32" />
        </div>
        <div className={styles.side_box}>
          <span>Wrapped Holdings</span>

          <span> {wceto || wceto == 0 ? wceto.toFixed(3) : ""} WCETO</span>
          <span> {ceto || ceto == 0 ? ceto.toFixed(3) : ""} CETO</span>
        </div>
      </div>
      <div className={styles.boxx}>
        <div className={styles.imgg}>
          <img src={contract_img} width="32" height="32" />
        </div>
        <div className={styles.side_box}>
          <span>Rate (CETO/WCETO)</span>
          <span> {rate || (rate == 0 && parseInt(rate).toFixed(3))}</span>
        </div>
      </div>
    </div>
    // <div className={`${styles.currencies_container}`}>
    //   <p className={styles.price}>My Holdings</p>
    //   <img
    //     className={styles.ele}
    //     src="https://res.cloudinary.com/dgdjabqwr/image/upload/v1609936435/cd3d/Group_5168_1_dqauem.png"
    //   ></img>
    //   <div className={styles.divs}> {wceto || wceto == 0 ? wceto.toFixed(3) : ""} WCETO</div>
    //   <div className={styles.cconvert}>
    //     <div className={styles.pc}>
    //       <div className={styles.amount}>{ceto || ceto == 0 && ceto.toFixed(3)} CETO</div>

    //       {/* <div className={styles.acurrency}>(if reinvested)</div> */}
    //     </div>
    //   </div>
    //   <div className={styles.cconvert}>
    //     <div className={styles.pc}>
    //       <div className={styles.amount}>{rate || rate == 0 && rate.toFixed(3)} Rate</div>
    //     </div>
    //   </div>
    // </div>
  );
};

const mapStateToProps = state => {
  return {
    utils: state.utils,
  };
};

export default connect(mapStateToProps, actions)(WrappedHoldings);
